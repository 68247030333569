import FormInput from '@components/ui/Form/FormInput'
import { UiText } from '@components/ui/UiText/UiText'
import { TicketPaymentFormType } from '@modules/Reception/types'
import { SelectButton } from 'primereact/selectbutton'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { IDisabled } from '../Form'

export type TPaymentType = { type: 'cash' | 'online' | 'ukassa'; ru: string; id: 0 | 1 | 7 }

export const typePaymentArray: TPaymentType[] = [
  { type: 'cash', ru: 'Наличными', id: 0 },
  { type: 'online', ru: 'Картой', id: 1 },
  { type: 'ukassa', ru: 'Онлайн', id: 7 },
]

interface ITotalPrice extends IDisabled {
  status: number
}

export const TotalPrice = ({ disable, status }: ITotalPrice) => {
  const methods = useFormContext<TicketPaymentFormType>()

  const tickets = useWatch({ control: methods.control, name: 'newTickets' })
  const services = useWatch({ control: methods.control, name: 'services' })
  const totalPrice = useWatch({ name: 'totalPrice' })
  const totalAmount = useWatch({ name: 'totalAmount' })
  const userBonuses = useWatch({ name: 'order.user.bonus' })
  const user = useWatch({ name: 'order.user' })
  const orderBonuses = useWatch({ control: methods.control, name: 'order.bonuses' })
  const preorderSum = useWatch({ control: methods.control, name: 'preorderSum' })

  useEffect(() => {
    let total = 0
    tickets.forEach((e) => {
      total += e!.totalPrice!
    })
    services.forEach((e) => {
      total += e!.totalPrice!
    })
    methods.setValue('totalPrice', total)
    methods.setValue('totalAmount', Math.max(0, total - orderBonuses))
  }, [tickets, services])

  useEffect(() => {
    if (orderBonuses !== 0) {
      methods.trigger('order.bonuses')
      methods.trigger('preorderSum')
    }
  }, [totalPrice, totalAmount])

  useEffect(() => {
    methods.resetField('order.bonuses')
  }, [user])

  useEffect(() => {
    methods.setValue('totalAmount', Math.max(0, totalPrice - orderBonuses))
  }, [orderBonuses])

  return (
    <section>
      <div className='flex items-center gap-4'>
        <i className='pi pi-credit-card' />
        <UiText className='font-bold !text-lg mr-4'>Оплата</UiText>
        <SelectButton
          value={methods.watch('paymentType')}
          onChange={(e) => methods.setValue('paymentType', e.value)}
          optionLabel='ru'
          options={typePaymentArray}
          className='selectButton'
          allowEmpty={false}
          disabled={disable && status !== 5}
        />
      </div>
      <div className='flex items-center gap-4 my-[20px]'>
        <UiText className='min-w-[100px]'>
          Бонусы {!disable && <UiText className='text-grey text-sm'>({methods.watch('order.user.bonus')})</UiText>}
        </UiText>

        <FormInput
          placeholder={`Сумма, макс. ${Math.min(userBonuses, Math.ceil(totalPrice * 0.3))}`}
          name='order.bonuses'
          type='number'
          min={0}
          max={!disable ? Math.min(userBonuses, totalPrice * 0.3) : undefined}
          className='!w-[150px]'
          disabled={disable || userBonuses === 0}
        />
      </div>
      <div className='flex items-center gap-4 my-[20px]'>
        <UiText className='min-w-[100px]'>Предоплата</UiText>

        <FormInput
          placeholder='Cумма предоплаты'
          name='preorderSum'
          type='number'
          min={0}
          max={totalPrice}
          className='!w-[150px]'
          disabled={disable}
        />
      </div>
      <div className='w-[506px] flex justify-between border-0 border-solid border-t-[1px] border-[#D7D8DA] py-2 items-center'>
        <UiText className='font-medium !text-md'>Сумма заказа</UiText>
        <UiText className='font-medium !text-2lg'>{totalAmount}</UiText>
      </div>
      {disable && status !== 4 && (
        <div className='w-[506px] flex justify-between py-2 items-center'>
          <UiText className='font-bold !text-md'>К оплате</UiText>
          <UiText className='font-bold !text-2lg'>
            {status === 5 ? totalAmount - preorderSum : preorderSum !== 0 ? preorderSum : totalAmount}
          </UiText>
        </div>
      )}
    </section>
  )
}

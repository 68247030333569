import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic } from '@hooks/storeHook'
import { formatPhone } from '@utils/textUtils'
import cn from 'classnames'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { FC, useContext } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ContactFormType } from '../../types'
import { IMain } from '../Main'

export const Form: FC<IMain> = ({ contact, updateUserHandler }) => {
  const logic = useLogic()
  const navigate = useNavigate()
  const context = useContext(ToastContext)

  const methods = useForm<ContactFormType>({
    defaultValues: {
      phone: formatPhone(contact.phone!),
      name: contact.name,
      email: contact.email,
      children: contact.children,
      is_information_agreed: Boolean(contact.information_agree),
      is_loyalty_agreed: Boolean(contact.loyalty_agree),
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: 'children',
    control: methods.control,
  })

  const addChildHandler = () => {
    append({ name: '', birth: new Date('2001-01-01') })
  }

  const removeChidlHandler = (index: number) => {
    remove(index)
  }

  const toTicketSaleHandler = () => {
    window.sessionStorage.setItem('contact_name', contact.name)
    window.sessionStorage.setItem('contact_phone', formatPhone(contact.phone!))

    contact.children?.length && window.sessionStorage.setItem('contact_children', JSON.stringify(contact.children))
    contact.email && window.sessionStorage.setItem('contact_email', contact.email)

    navigate(AppRoutesPaths.TicketSale)
  }

  const toConstructorHandler = () => {
    window.sessionStorage.setItem('contact_name', contact.name)
    window.sessionStorage.setItem('contact_phone', formatPhone(contact.phone!))

    contact.children?.length && window.sessionStorage.setItem('contact_children', JSON.stringify(contact.children))
    contact.email && window.sessionStorage.setItem('contact_email', contact.email)

    navigate(AppRoutesPaths.Constructor)
  }

  const onSubmit = async (data: ContactFormType) => {
    console.log('click')
    const formData = new FormData()
    methods.getFieldState('name').isDirty && formData.append('name', data.name)
    data.email && methods.getFieldState('email').isDirty && formData.append('email', data.email)
    formData.append('information_agree', String(+data.is_information_agreed))
    formData.append('loyalty_agree', String(+data.is_loyalty_agreed))

    let isChildrenDirty = false

    data.children?.forEach((e, i) => {
      if (methods.getFieldState(`children.${i}`).isDirty) {
        isChildrenDirty = true
        return
      }
    })

    if (data.children?.length !== contact.children?.length || isChildrenDirty) {
      data.children?.forEach((element, i) => {
        formData.append(`kids[${i}][name]`, element!.name!)
        formData.append(`kids[${i}][birth]`, moment(element.birth, 'YYYY-MM-DD').toDate().toLocaleDateString('en-CA'))
      })
    }

    const response = await logic.updateClient(contact.id, formData)
    context?.toastShowHandler({ status: response.status, errors: response.errors })
    response.data && updateUserHandler && updateUserHandler(response.data)
  }

  return (
    <FormProvider {...methods}>
      <form className='flex flex-col gap-10 h-full' onSubmit={methods.handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center'>
            <UiText className='text-lg font-bold mr-[42px]'>Гость</UiText>
            <UiButton className='mr-3 w-[193px]' type='button' onClick={toTicketSaleHandler}>
              Продать билет
            </UiButton>
            <UiButton className='w-[193px]' type='button' onClick={toConstructorHandler} color='blue'>
              Продать праздник
            </UiButton>
          </div>
          <FormInput className='w-[389px]' name={`phone`} placeholder='Введите номер телефона' disabled />
          <FormInput className='w-[389px]' name={`name`} placeholder='Введите имя' />
          <FormInput
            className='w-[389px]'
            name={`email`}
            placeholder='Введите email'
            customPattern={
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            }
          />
          <div className='flex items-center gap-[8px]'>
            <Controller
              name='is_information_agreed'
              control={methods.control}
              render={({ field }) => (
                <>
                  <Checkbox
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    onChange={(e) => field.onChange(e.checked)}
                  />
                </>
              )}
            />
            <UiText className='text-grey'>Согласен с программой рассылок</UiText>
          </div>
          <div className='flex items-center gap-[8px]'>
            <Controller
              name='is_loyalty_agreed'
              control={methods.control}
              render={({ field }) => (
                <>
                  <Checkbox
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    onChange={(e) => field.onChange(e.checked)}
                  />
                </>
              )}
            />
            <UiText className='text-grey'>Согласен с бонусной программой</UiText>
          </div>
        </div>

        <div className='flex flex-col gap-4'>
          <div className='flex items-center'>
            <UiText className='text-lg font-bold mr-5'>Дети</UiText>
            <UiButton onClick={addChildHandler} type='button'>
              <i className='pi pi-plus text-sm'></i>
            </UiButton>
          </div>
          {fields.map((e, i) => (
            <div className='flex gap-4 max-w-[389px]' key={e.id}>
              <FormInput
                containerClassName='max-w-[191px] w-full'
                name={`children.${i}.name`}
                placeholder='Введите имя'
              />
              <Controller
                control={methods.control}
                name={`children.${i}.birth`}
                rules={{ required: 'Date is required.' }}
                render={({ field, fieldState }) => (
                  <Calendar
                    value={moment(field.value, 'YYYY-MM-DD').toDate()}
                    onChange={field.onChange}
                    showIcon={true}
                    dateFormat='dd.mm.yy'
                    locale='ru'
                    className={cn(`w-[130px] worker shadow-none`, fieldState.error && 'worker-error')}
                    maxDate={new Date()}
                    placeholder='ДД.ММ.ГГГГ'
                    inputClassName='shadow-none'
                    mask='99.99.9999'
                  />
                )}
              />
              {i > 0 && (
                <UiButton onClick={() => removeChidlHandler(i)} type='button' color='red'>
                  <i className='pi pi-times text-sm'></i>
                </UiButton>
              )}
            </div>
          ))}
        </div>
        <UiButton className='mt-auto max-w-[389px]' type='submit'>
          Сохранить
        </UiButton>
      </form>
    </FormProvider>
  )
}

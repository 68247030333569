import { ApiSale } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import FormMultiselect from '@components/ui/Form/FormMultiselect/FormMultiselect'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { IStories } from '@modules/Advertising/Stories/StoriesPage'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'primereact/dropdown'
import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { bonusTypes, triggers } from '../LoyaltyPage'
import { TLoyaltyForm } from '../types/types'

export const LoyaltyFormPage: FC<IStories> = observer(({ isAdmin }) => {
  const methods = useForm<TLoyaltyForm>()
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    auth: { selectedPark },
    advertising: { sales },
    ticket: { tickets, adminTickets },
    services: { services, adminServices },
  } = useStore()

  const { id } = useParams()

  const [triggerContent, setTriggerContent] = useState<ReactNode>(null)
  const [initialValue, setInitialValue] = useState<ApiSale | undefined>()
  const [loading, setLoading] = useState<boolean>(true)

  const onCreate = async (data: TLoyaltyForm) => {
    const formData = createFormData({
      name: data.name,
      park_id: selectedPark!.id,
      trigger_type: data.trigger.id,
      duration: data.duration,
      bonus_type: data.type.id,
      discount_value: data.bonuses,
      active: 1,
    })

    if (data.triggerNumber) formData.append('trigger_value', String(data.triggerNumber))
    else if (data.triggerEntities.length > 0)
      data.triggerEntities.forEach((e, i) => {
        if (data.trigger.id === 3) formData.append(`services[${i}]`, String(e.id))
        if (data.trigger.id === 4) formData.append(`tickets[${i}]`, String(e.id))
      })

    const { status, errors } = isAdmin ? await logic.createAdminSale(formData) : await logic.createSale(formData)
    if (status) navigateTo(isAdmin ? AppRoutesPaths.AdminPanelLoyalty : AppRoutesPaths.AdvertisingLoyalty)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const onEdit = async (data: TLoyaltyForm) => {
    let query = `?name=${data.name}&trigger_type=${data.trigger.id}&duration=${data.duration}&bonus_type=${data.type.id}&discount_value=${data.bonuses}`

    if (data.triggerNumber) query += `&trigger_value=${data.triggerNumber}`
    else if (data.triggerEntities.length > 0)
      data.triggerEntities.forEach((e, i) => {
        if (data.trigger.id === 3) query += `&services[${i}]=${e.id}`
        if (data.trigger.id === 4) query += `&tickets[${i}]=${e.id}`
      })

    const { status, errors } = isAdmin ? await logic.updateAdminSale(query, +id!) : await logic.updateSale(query, +id!)
    if (status) navigateTo(isAdmin ? AppRoutesPaths.AdminPanelLoyalty : AppRoutesPaths.AdvertisingLoyalty)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const init = async () => {
    if (!selectedPark) return
    setLoading(true)
    if (!tickets.length) await logic.loadTicket()
    if (!services.length) await logic.loadServices()

    if (id && sales.length === 0) {
      logic
        .loadSaleById(+id)
        .then((res) => setInitialValue(res.data))
        .finally(() => setLoading(false))
    } else if (sales.length !== 0 && id) {
      setInitialValue(sales.find((e) => e.id === +id))
      setLoading(false)
    } else setLoading(false)
  }

  const initAdmin = async () => {
    if (!selectedPark) return
    setLoading(true)
    if (!tickets.length) await logic.loadAdminTickets()
    if (!services.length) await logic.loadAdminServices()

    if (id && sales.length === 0) {
      logic
        .loadSaleById(+id)
        .then((res) => setInitialValue(res.data))
        .finally(() => setLoading(false))
    } else if (sales.length !== 0 && id) {
      setInitialValue(sales.find((e) => e.id === +id))
      setLoading(false)
    } else setLoading(false)
  }

  useEffect(() => {
    isAdmin ? initAdmin() : init()
  }, [selectedPark])

  useEffect(() => {
    const initialTrigger = triggers.find((e) => e.id === initialValue?.trigger_type)
    const content = triggerValueSwitcher(initialTrigger?.id)
    setTriggerContent(content)
    methods.reset({
      name: initialValue?.name,
      trigger: initialTrigger,
      duration: initialValue?.duration,
      type: bonusTypes.find((e) => e.id === initialValue?.bonus_type),
      bonuses: initialValue?.discount_value,
      triggerNumber: initialValue?.trigger_value ?? undefined,
      triggerEntities:
        initialTrigger?.id === 4
          ? isAdmin
            ? adminTickets.filter((t) => initialValue?.tickets.some((e) => e.id === t.id))
            : tickets.filter((t) => initialValue?.tickets.some((e) => e.id === t.id))
          : isAdmin
            ? adminServices.filter((t) => initialValue?.services.some((e) => e.id === t.id))
            : services.filter((t) => initialValue?.services.some((e) => e.id === t.id)),
    })
  }, [initialValue])

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === 'trigger') {
        const content = triggerValueSwitcher(value.trigger?.id)
        setTriggerContent(content)
      }
    })

    return () => subscription.unsubscribe()
  }, [methods.watch, tickets, services, adminTickets, adminServices])

  const triggerValueSwitcher = (triggerId: number | undefined) => {
    methods.setValue('triggerEntities', [])
    methods.setValue('triggerNumber', undefined)
    switch (triggerId) {
      case 1:
        return <></>
      case 2:
        return (
          <FormInput
            className={`text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
            containerClassName='col-start-2'
            title='Настрой триггера'
            name='triggerNumber'
            required
          />
        )
      case 3:
        return (
          <FormMultiselect
            name='triggerEntities'
            placeholder='Выберите услуги'
            customSize='full'
            options={isAdmin ? adminServices : services}
            title='Настрой триггера'
            required
          />
        )
      case 4:
        return (
          <FormMultiselect
            name='triggerEntities'
            placeholder='Выберите билеты'
            customSize='full'
            options={isAdmin ? adminTickets : tickets}
            title='Настрой триггера'
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Layout scrollable title={initialValue ? 'Изменить начисление' : 'Добавить начисление'} loading={loading}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(initialValue ? onEdit : onCreate)} className='h-full w-full flex flex-col'>
          <div className='grid grid-cols-[repeat(3,233px)] gap-y-4 gap-x-9'>
            <FormInput
              className={`text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
              containerClassName='col-start-1 col-end-3'
              title='Название'
              name='name'
              required
            />

            <div className='gap-[4px] flex flex-col w-[100%] col-start-1'>
              <UiText className='text-grey'>Триггер</UiText>

              <Controller
                name='trigger'
                control={methods.control}
                rules={{ required: 'Required' }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={triggers}
                    optionLabel='name'
                    placeholder='Выберите триггер'
                    className={`w-full md:w-14rem border-green worker ${fieldState.error ? '!border-red' : ''}`}
                    panelClassName='worker-panel'
                    color='green'
                  />
                )}
              />
            </div>

            {triggerContent}

            <FormInput
              className={`text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
              containerClassName='col-start-1'
              title='Срок действия'
              name='duration'
              required
              customPattern={/^\d+$/}
            />

            <div className='gap-[4px] flex flex-col w-[100%] col-start-2'>
              <UiText className='text-grey'>Тип бонуса</UiText>

              <Controller
                name='type'
                control={methods.control}
                rules={{ required: 'Required' }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={bonusTypes}
                    optionLabel='name'
                    placeholder='Выберите тип бонуса'
                    className={`w-full md:w-14rem border-green worker ${fieldState.error && '!border-red'}`}
                    panelClassName='worker-panel'
                    color='green'
                  />
                )}
              />
            </div>

            <FormInput
              className={`text-field bg-white px-[8px] py-[10px] h-[36px] border-solid border-[1px] text-grey border-green rounded-[3px] focus:outline-none`}
              containerClassName='col-start-3'
              title='Размер бонуса'
              name='bonuses'
              required
              customPattern={/^\d+$/}
            />
          </div>

          <UiButton className='w-[233px] hover:bg-green-[#16A34A] !font-black  justify-center max-h-[36px] uppercase !text-sm col-start-1 mt-auto'>
            {initialValue ? 'Изменить' : 'Добавить'}
          </UiButton>
        </form>
      </FormProvider>
    </Layout>
  )
})

import {
  ApiAnimator,
  ApiApplication,
  ApiAttraction,
  ApiAuthorizationData,
  ApiBirthdayGame,
  ApiCategoryType,
  ApiCheck,
  ApiCreateCategory,
  ApiCreateCategoryResponse,
  ApiCreateTicketForm,
  ApiDataCities,
  ApiDataParks,
  ApiDataPost,
  ApiDiscount,
  ApiEmployee,
  ApiGame,
  ApiGameZone,
  ApiHoliday,
  ApiNewsLetter,
  ApiNote,
  ApiObjPark,
  ApiPC,
  ApiPayment,
  ApiProduct,
  ApiRoom,
  ApiRoomType,
  ApiSale,
  ApiScheduleApplication,
  ApiServices,
  ApiServicesCreateForm,
  ApiServicesMeasures,
  ApiServicesTypes,
  ApiServicesUnits,
  ApiShowsServices,
  ApiStories,
  ApiTicket,
  ApiUser,
  ApiUsersPaginationResponse,
  Park,
  Role,
} from '@@types/apiLogicTypes'
import { VisibleError } from '@common/visibleError'

import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
interface IRes {
  message: string
  success: boolean
}

export class ApiLogic {
  protected readonly axios: AxiosInstance

  constructor() {
    this.axios = Axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: { Accept: 'application/json' },
      timeout: 20 * 1000,
    })

    this.axios.interceptors.request.use(this.interceptRequest, this.interceptRequestError)

    this.axios.interceptors.response.use(this.interceptResponse, this.interceptResponseError)
  }

  private interceptRequest = async (request: AxiosRequestConfig) => {
    return request
  }

  private interceptRequestError = (error?: Partial<AxiosError>) => {
    if (Axios.isCancel(error)) {
      throw new VisibleError('Запрос отменён', error)
    } else {
      throw error
    }
  }

  private interceptResponse = (response: AxiosResponse) => {
    return response.data
  }

  private interceptResponseError = async (error?: Partial<AxiosError>) => {
    if (Axios.isCancel(error)) {
      throw new VisibleError('Запрос отменён', error)
    }

    throw error
  }

  protected authConfig = (authToken: string) => ({ headers: { Authorization: `Bearer ${authToken}` } }) as const
  private putConfig = (authToken: string) => {
    return {
      headers: { Authorization: `Bearer ${authToken}` },
      ContentType: 'application/x-www-form-urlencoded',
    }
  }
  loadUser = (token: string): Promise<ApiEmployee> => this.axios.get('user', this.authConfig(token)).then(getData)

  postAuthorization = async (data: ApiAuthorizationData): Promise<ApiDataPost> =>
    this.axios.post('auth/login', data).then(getData)

  getParks = async (token: string): Promise<ApiDataParks> =>
    this.axios.get('park', this.authConfig(token)).then(getData)

  getCities = async (token: string): Promise<ApiDataCities> =>
    this.axios.get('city', this.authConfig(token)).then(getData)

  postAddPark = async (form: ApiObjPark, token: string): Promise<ApiDataParks> =>
    this.axios.post('park/create', form, this.authConfig(token)).then(getData)

  editPark = async (form: FormData | ApiObjPark, token: string, id: number): Promise<Park> =>
    this.axios.post(`park/${id}/update`, form, this.authConfig(token)).then(getData)

  getCategory = async (token: string, parkId?: string): Promise<ApiCreateCategoryResponse[]> =>
    this.axios.get(`category?park_id=${parkId}`, this.authConfig(token)).then(getData)

  getCategoryById = async (token: string, parkId: number): Promise<ApiCreateCategoryResponse> =>
    this.axios.get(`category/${parkId}`, this.authConfig(token)).then(getData)

  getCategoryType = async (token: string): Promise<ApiCategoryType> =>
    this.axios.get(`category/types`, this.authConfig(token)).then(getData)

  postCreateCategory = async (token: string, categoryObj: ApiCreateCategory): Promise<ApiCreateCategoryResponse> =>
    this.axios.post(`category/create`, categoryObj, this.authConfig(token)).then(getData)

  deleteCategory = async (token: string, id: number): Promise<any> =>
    this.axios.delete(`category/${id}/delete`, this.authConfig(token)).then(getData)

  updateCategory = async (token: string, id: string, name: string): Promise<ApiCreateCategoryResponse> =>
    this.axios.put(`category/${id}/update`, { id, name }, this.authConfig(token)).then(getData)

  getServices = async (token: string, id?: number, deletedFlag?: true): Promise<ApiServices[]> =>
    this.axios.get(`services/?park_id=${id + (deletedFlag ? '&deleted=1' : '')}`, this.authConfig(token)).then(getData)

  getAdminServices = async (token: string): Promise<ApiServices[]> =>
    this.axios.get(`admin/services/groups`, this.authConfig(token)).then(getData)

  getAdminShowsServices = async (token: string): Promise<ApiShowsServices[]> =>
    this.axios.get(`/services/groups/shows`, this.authConfig(token)).then(getData)

  getServicesById = async (token: string, id: string): Promise<ApiServices> =>
    this.axios.get(`services/${id}`, this.authConfig(token)).then(getData)

  getAdminServicesById = async (token: string, id: string): Promise<ApiServices> =>
    this.axios.get(`admin/services/groups/${id}`, this.authConfig(token)).then(getData)

  getServicesUnits = async (token: string): Promise<ApiServicesUnits[]> =>
    this.axios.get(`services/units`, this.authConfig(token)).then(getData)

  getServicesMeasures = async (token: string): Promise<ApiServicesMeasures[]> =>
    this.axios.get(`services/measures`, this.authConfig(token)).then(getData)

  getServicesTypes = async (token: string, id: number): Promise<ApiServicesTypes[]> =>
    this.axios.get(`services/types?park_id=${id}`, this.authConfig(token)).then(getData)

  postCreateServices = async (token: string, form: FormData): Promise<ApiServices> =>
    this.axios.post(`services/create`, form, this.authConfig(token)).then(getData)

  postAdminCreateServices = async (token: string, form: FormData): Promise<ApiServices> =>
    this.axios.post(`admin/services/create`, form, this.authConfig(token)).then(getData)

  deleteService = async (token: string, id: number): Promise<any> =>
    this.axios.delete(`services/${id}/delete`, this.authConfig(token)).then(getData)

  putServices = async (token: string, id: number, form: Partial<ApiServicesCreateForm>): Promise<ApiServices> =>
    this.axios.post(`services/${id}/update`, form, this.putConfig(token)).then(getData)

  postAdminService = async (token: string, id: string, form: FormData): Promise<ApiServices> =>
    this.axios.post(`admin/services/${id}/update`, form, this.putConfig(token)).then(getData)

  loadRooms = async (token: string, id: number, deletedFlag?: boolean): Promise<ApiRoom[]> =>
    this.axios.get(`room/?park_id=${id + (deletedFlag ? '&deleted=1' : '')}`, this.authConfig(token)).then(getData)
  deleteRoom = async (token: string, id: number): Promise<any> =>
    this.axios.delete(`room/${id}/delete`, this.authConfig(token)).then(getData)

  getRoomById = async (token: string, id: string): Promise<ApiRoom> =>
    this.axios.get(`room/${id}`, this.authConfig(token)).then(getData)

  getTicket = async (token: string, id: string, deletedFlag?: true): Promise<ApiTicket[]> =>
    this.axios.get(`ticket?park_id=${id + (deletedFlag ? '&deleted=1' : '')}`, this.authConfig(token)).then(getData)

  deleteTicket = async (token: string, id: number): Promise<any> =>
    this.axios.delete(`ticket/${id}/delete`, this.authConfig(token)).then(getData)

  getTicketById = async (token: string, id: string): Promise<ApiTicket> =>
    this.axios.get(`ticket/${id}`, this.authConfig(token)).then(getData)

  getAdminTicketById = async (token: string, id: string): Promise<ApiTicket> =>
    this.axios.get(`admin/ticket/groups/${id}`, this.authConfig(token)).then(getData)

  postCreateTicket = async (token: string, form: ApiCreateTicketForm): Promise<ApiTicket> =>
    this.axios.post(`ticket/create`, form, this.authConfig(token)).then(getData)

  postCreateTickets = async (token: string, form: ApiCreateTicketForm): Promise<ApiTicket> =>
    this.axios.post(`admin/ticket/create`, form, this.authConfig(token)).then(getData)

  getAdminTickets = async (token: string): Promise<ApiTicket[]> =>
    this.axios.get(`admin/ticket/groups`, this.authConfig(token)).then(getData)

  putUpdateTicket = async (token: string, form: ApiCreateTicketForm): Promise<ApiTicket> =>
    this.axios.put(`ticket/${form.id}/update`, form, this.authConfig(token)).then(getData)

  putUpdateAdminTickets = async (token: string, form: ApiCreateTicketForm): Promise<ApiTicket> =>
    this.axios.put(`admin/ticket/${form.id}/update`, form, this.authConfig(token)).then(getData)

  getTicketsTypes = async (token: string, id: number): Promise<ApiServicesTypes[]> =>
    this.axios.get(`ticket/types?park_id=${id}`, this.authConfig(token)).then(getData)

  getAttraction = async (token: string, parkId: number, deletedFlag?: true): Promise<ApiAttraction[]> =>
    this.axios
      .get(`attraction?park_id=${parkId + (deletedFlag ? '&deleted=1' : '')}`, this.authConfig(token))
      .then(getData)
  deleteAttration = async (token: string, id: number): Promise<any> =>
    this.axios.delete(`attraction/${id}/delete`, this.authConfig(token)).then(getData)

  getAdminAttraction = async (token: string): Promise<ApiAttraction[]> =>
    this.axios.get(`admin/attraction/groups`, this.authConfig(token)).then(getData)

  getAttractionById = async (token: string, id: string): Promise<ApiAttraction> =>
    this.axios.get(`attraction/${id}`, this.authConfig(token)).then(getData)

  getAdminAttractionById = async (token: string, id: string): Promise<ApiAttraction> =>
    this.axios.get(`admin/attraction/groups/${id}`, this.authConfig(token)).then(getData)

  postCreateAttraction = async (token: string, form: FormData): Promise<ApiAttraction> =>
    this.axios.post(`admin/attraction/create`, form, this.authConfig(token)).then(getData)

  postCreateAdminAttractions = async (token: string, form: FormData): Promise<ApiAttraction> =>
    this.axios.post(`attraction/create`, form, this.authConfig(token)).then(getData)

  postUpdateAttraction = async (token: string, form: FormData, id: string): Promise<ApiAttraction> =>
    this.axios.post(`attraction/${id}/update`, form, this.authConfig(token)).then(getData)

  postAdminUpdateAttraction = async (token: string, form: FormData, id: string): Promise<ApiAttraction> =>
    this.axios.post(`admin/attraction/${id}/update`, form, this.authConfig(token)).then(getData)

  getRoomsTypes = async (token: string, id: number): Promise<ApiRoomType[]> =>
    this.axios.get(`room/types?park_id=${id}`, this.authConfig(token)).then(getData)

  postCreateRoom = async (token: string, form: FormData): Promise<ApiRoom> =>
    this.axios.post(`room/create`, form, this.authConfig(token)).then(getData)

  putUpdateRoom = async (token: string, id: number, form: FormData): Promise<ApiRoom> =>
    this.axios.post(`room/${id}/update`, form, this.putConfig(token)).then(getData)

  getShowsServices = async (token: string, id: number): Promise<ApiShowsServices[]> =>
    this.axios.get(`services/shows?park_id=${id}`, this.authConfig(token)).then(getData)

  getEntertaimentProgramsServices = async (token: string, id: number): Promise<ApiShowsServices[]> =>
    this.axios.get(`services/programs?park_id=${id}`, this.authConfig(token)).then(getData)

  getProduct = async (token: string, id: number): Promise<ApiProduct[]> =>
    this.axios.get(`product?park_id=${id}`, this.authConfig(token)).then(getData)

  getHolidays = async (token: string, id: number, deletedFlag?: true): Promise<ApiHoliday[]> =>
    this.axios.get(`holiday?park_id=${id + (deletedFlag ? '&deleted=1' : '')}`, this.authConfig(token)).then(getData)

  getAdminHolidays = async (token: string): Promise<ApiHoliday[]> =>
    this.axios.get(`holiday/groups`, this.authConfig(token)).then(getData)

  deleteHoliday = async (token: string, id: number): Promise<any> =>
    this.axios.delete(`holiday/${id}/delete`, this.authConfig(token)).then(getData)

  getHolidayById = async (token: string, id: string): Promise<ApiHoliday> =>
    this.axios.get(`holiday/${id}`, this.authConfig(token)).then(getData)

  getAdminHolidayById = async (token: string, id: string): Promise<ApiHoliday> =>
    this.axios.get(`holiday/group/${id}`, this.authConfig(token)).then(getData)

  postCreateHoliday = async (token: string, url: URLSearchParams): Promise<ApiHoliday> =>
    this.axios.post(`holiday/create?${url}`, {}, this.authConfig(token)).then(getData)

  postCreateAdminHoliday = async (token: string, url: URLSearchParams): Promise<ApiHoliday> =>
    this.axios.post(`holiday/group/create?${url}`, {}, this.authConfig(token)).then(getData)

  putUpdateHoliday = async (token: string, url: URLSearchParams, id: string): Promise<ApiHoliday> =>
    this.axios.put(`holiday/${id}/update?${url}`, {}, this.authConfig(token)).then(getData)

  putUpdateAdminHoliday = async (token: string, url: URLSearchParams, id: string): Promise<ApiHoliday> =>
    this.axios.put(`holiday/group/${id}/update?${url}`, {}, this.authConfig(token)).then(getData)

  getApplications = async (token: string): Promise<ApiApplication[]> =>
    this.axios.get(`requests`, this.authConfig(token)).then(getData)

  getApplicationById = async (token: string, id: number): Promise<ApiApplication> =>
    this.axios.get(`requests/${id}`, this.authConfig(token)).then(getData)

  createApplication = async (formData: FormData): Promise<ApiApplication[]> =>
    this.axios.post(`order/create`, formData).then(getData)

  updateApplication = async (id: number, formData: FormData, token: string): Promise<ApiApplication[]> =>
    this.axios.post(`order/${id}/update?_method=PUT`, formData, this.authConfig(token)).then(getData)

  createAdminApplication = async (token: string, formData: FormData) =>
    this.axios.post(`order/admin/create`, formData, this.authConfig(token)).then(getData)

  addNoteForOrder = async (id: number, formData: FormData, token: string): Promise<ApiNote> =>
    this.axios.post(`order/${id}/note/add`, formData, this.authConfig(token)).then(getData)

  updateApplicationsByStatus = async (
    token: string,
    status: number,
    applications: { id: number; sort: number }[]
  ): Promise<ApiApplication[]> =>
    this.axios.put(`requests/update`, { status: status, list: applications }, this.authConfig(token)).then(getData)

  updateApplicationsById = async (token: string, id: number, status: number): Promise<ApiApplication[]> =>
    this.axios.put(`requests/${id}/update`, { status: status, sort: 0 }, this.authConfig(token)).then(getData)

  deleteApplicationsById = async (token: string, id: number): Promise<ApiApplication[]> =>
    this.axios.delete(`requests/${id}/delete`, this.authConfig(token)).then(getData)

  getStories = async (token: string, id: number): Promise<ApiStories[]> =>
    this.axios.get(`stories/?park_id=${id}`, this.authConfig(token)).then(getData)

  getAdminStories = async (token: string): Promise<ApiStories[]> =>
    this.axios.get(`stories/groups`, this.authConfig(token)).then(getData)

  createStory = async (token: string, form: FormData) =>
    this.axios.post(`stories/create`, form, this.authConfig(token)).then(getData)

  createAdminStory = async (token: string, form: FormData) =>
    this.axios.post(`stories/group/create`, form, this.authConfig(token)).then(getData)

  updateStory = async (token: string, id: number, form: FormData): Promise<ApiStories> =>
    this.axios.post(`stories/${id}/update`, form, this.putConfig(token)).then(getData)

  updateAdminStory = async (token: string, id: number, form: FormData): Promise<ApiStories> =>
    this.axios.post(`stories/group/${id}/update`, form, this.putConfig(token)).then(getData)

  deleteStories = async (token: string, id: number): Promise<ApiStories> =>
    this.axios.delete(`stories/${id}/delete`, this.putConfig(token)).then(getData)

  loadSchedule = (token: string, id: number, dataFrom: string, dataTo: string): Promise<ApiScheduleApplication[]> =>
    this.axios.get(`schedule/?park_id=${id}&from=${dataFrom}&to=${dataTo}`, this.authConfig(token)).then(getData)

  updateScheduleOrder = (token: string, id: number, start: string, end: string): Promise<ApiScheduleApplication[]> =>
    this.axios
      .put(`schedule/${id}/update?starts_at=${start}&ends_at=${end}`, null, this.authConfig(token))
      .then(getData)

  searchUser = (token: string, phone?: string, query?: string): Promise<ApiUser[]> =>
    this.axios
      .get(
        `user/search?${phone ? `phone=${phone}` : ''}${query ? `searchString=${query}` : ''}`,
        this.authConfig(token)
      )
      .then(getData)

  getClients = (
    token: string,
    parkId: number,
    page: number,
    dateFrom?: string,
    dateTo?: string
  ): Promise<ApiUsersPaginationResponse> =>
    this.axios.get(
      `users/clients?park_id=${parkId}${page ? `&page=${page}` : ''}${dateFrom ? `&created_from=${dateFrom}` : ''}${
        dateTo ? `&created_to=${dateTo}` : ''
      }`,
      this.authConfig(token)
    )

  getClientById = (token: string, id: number): Promise<ApiUser> =>
    this.axios.get(`users/clients/${id}`, this.authConfig(token)).then(getData)

  addNoteForClient = async (id: number, formData: FormData, token: string): Promise<ApiNote> =>
    this.axios.post(`users/clients/${id}/note/add`, formData, this.authConfig(token)).then(getData)

  updateClient = async (id: number, formData: FormData, token: string): Promise<ApiUser> =>
    this.axios.post(`users/clients/${id}/update?_method=PUT`, formData, this.authConfig(token)).then(getData)

  getAnimators = (parkId: number, token: string): Promise<ApiAnimator[]> =>
    this.axios.get(`users/animators?park_id=${parkId}`, this.authConfig(token))

  sendIntegrationGame = (token: string, formData: FormData): Promise<IRes> =>
    this.axios.post('admin/integration/game/set', formData, this.authConfig(token))

  sendIntegrationPC = (formData: FormData, token: string): Promise<IRes> =>
    this.axios.post('admin/integration/computer/reload', formData, this.authConfig(token))

  getIntegrationBirthday = (token: string, parkId: number): Promise<ApiBirthdayGame[]> =>
    this.axios.get(`admin/integration/birthday/all?park_id=${parkId}`, this.authConfig(token)).then(getData)

  createIntegrationBirthday = (token: string, formData: FormData) =>
    this.axios.post('admin/integration/birthday/create', formData, this.authConfig(token)).then(getData)

  editIntegrationBirthday = (token: string, id: number, formData: FormData) =>
    this.axios.post(`admin/integration/birthday/${id}/update`, formData, this.authConfig(token)).then(getData)

  deleteIntegrationBirthday = (token: string, id: number) =>
    this.axios.delete(`admin/integration/birthday/${id}/delete`, this.authConfig(token))

  startIntegrationBirthday = (token: string, id: number): Promise<IRes> =>
    this.axios.post(`admin/integration/birthday/${id}/start`, undefined, this.authConfig(token))

  stopIntegrationBirthday = (token: string, id: number): Promise<IRes> =>
    this.axios.post(`admin/integration/birthday/${id}/stop`, undefined, this.authConfig(token))

  getIntegrationByPark = (
    id: number,
    token: string
  ): Promise<{ computers: ApiPC[]; games: ApiGame[]; zones: ApiGameZone[] }> =>
    this.axios.get(`admin/integration/all${id && '?park_id=' + id}`, this.authConfig(token)).then(getData)

  getAdminGames = (token: string) => this.axios.get('admin/integration/games', this.authConfig(token)).then(getData)

  setAdminGames = (token: string, formData: FormData) =>
    this.axios.post('admin/integration/games/sync', formData, this.authConfig(token))

  getAdminGamesTypes = (token: string) =>
    this.axios.get('admin/integration/games/types', this.authConfig(token)).then(getData)

  deleteGameZone = (id: number, token: string) =>
    this.axios.delete(`admin/integration/zone/${id}/delete`, this.authConfig(token))

  createGameZone = (formData: FormData, token: string): Promise<ApiGameZone> =>
    this.axios.post(`admin/integration/zone/create`, formData, this.authConfig(token)).then(getData)

  updateGameZone = (id: number, formData: FormData, token: string): Promise<ApiGameZone> =>
    this.axios.post(`admin/integration/zone/${id}/update?_method=PUT`, formData, this.authConfig(token)).then(getData)

  createPC = (formData: FormData, token: string): Promise<ApiPC> =>
    this.axios.post(`admin/integration/computer/create`, formData, this.authConfig(token)).then(getData)

  deletePC = (id: number, token: string) =>
    this.axios.delete(`admin/integration/computer/${id}/delete`, this.authConfig(token))

  updatePC = (id: number, formData: FormData, token: string): Promise<ApiGameZone> =>
    this.axios
      .post(`admin/integration/computer/${id}/update?_method=PUT`, formData, this.authConfig(token))
      .then(getData)

  createPaymentTicket = (formData: FormData, token: string) =>
    this.axios.post(`admin/payment/ticket/create`, formData, this.authConfig(token)).then(getData)

  createPaymentTicketByOrderId = (formData: FormData, id: number, token: string): Promise<ApiPayment> =>
    this.axios.post(`order/admin/${id}/ticket/payment/create`, formData, this.authConfig(token)).then(getData)

  applyPayment = (formData: FormData, id: number, token: string) =>
    this.axios.post(`admin/payment/${id}/apply?_method=PUT`, formData, this.authConfig(token)).then(getData)

  errorPaymentTicket = (formData: FormData, id: number, token: string) =>
    this.axios.post(`admin/payment/${id}/error?_method=PUT`, formData, this.authConfig(token)).then(getData)

  refoundPaymentTicket = (formData: FormData, id: number, token: string) =>
    this.axios.post(`admin/payment/${id}/refund?_method=PUT`, formData, this.authConfig(token)).then(getData)

  checkSetPaymentTicket = (formData: FormData, id: number, token: string): Promise<ApiCheck> =>
    this.axios.post(`admin/payment/${id}/check/set`, formData, this.authConfig(token)).then(getData)

  createPaymentHoliday = (orderId: number, formData: FormData, token: string) =>
    this.axios.post(`order/admin/${orderId}/holiday/payment/create`, formData, this.authConfig(token)).then(getData)

  getPayments = (token: string, parkId: number, page: number, dateFrom?: string, dateTo?: string) =>
    this.axios.get(
      `payments?active=1&park_id=${parkId}${page ? `&page=${page}` : ''}${dateFrom ? `&created_from=${dateFrom}` : ''}${
        dateTo ? `&created_to=${dateTo}` : ''
      }`,
      this.authConfig(token)
    )

  getPaymentById = (token: string, id: number): Promise<ApiPayment> =>
    this.axios.get(`payment/${id}`, this.authConfig(token)).then(getData)

  cashboxMinus = (token: string, formData: FormData) =>
    this.axios.post(`admin/payment/cashbox/logs/create`, formData, this.authConfig(token)).then(getData)

  updateLogReport = (token: string, id: number, formData: FormData) =>
    this.axios.post(`admin/payment/cashbox/logs/${id}/update`, formData, this.authConfig(token)).then(getData)

  cashboxCollection = (token: string, formData: FormData) =>
    this.axios.post(`admin/payment/cashbox/collection`, formData, this.authConfig(token)).then(getData)

  getCashboxSum = (token: string, id: number) =>
    this.axios.get(`admin/payment/cashbox/balance?park_id=${id}`, this.authConfig(token)).then(getData)

  getCashReports = (token: string, parkId: number, page: number, dateFrom?: string, dateTo?: string) =>
    this.axios
      .get(
        `admin/payment/cashbox/logs?park_id=${parkId}${page ? `&page=${page}` : ''}${
          dateFrom ? `&created_from=${dateFrom}` : ''
        }${dateTo ? `&created_to=${dateTo}` : ''}`,
        this.authConfig(token)
      )
      .then(getData)

  minusCashReport = async (token: string, id: number) =>
    this.axios.post(`admin/payment/cashbox/logs/${id}/minus`, null, this.authConfig(token)).then(getData)

  deleteCashReport = async (token: string, id: number) =>
    this.axios.delete(`admin/payment/cashbox/logs/${id}/delete`, this.authConfig(token)).then(getData)

  getEmployees = async (token: string, id?: number) =>
    this.axios.get(`/users/employees?park_id=${id}`, this.authConfig(token)).then(getData)

  getManagers = async (token: string, id?: number) =>
    this.axios.get(`/users/managers${id ? '?park_id=' + id : ''}`, this.authConfig(token)).then(getData)

  getEmployeeById = async (token: string, id?: number): Promise<ApiEmployee> =>
    this.axios.get(`/users/employees/${id}`, this.authConfig(token)).then(getData)

  postEmployee = async (token: string, form: FormData): Promise<ApiEmployee> =>
    this.axios.post(`users/employees/create`, form, this.authConfig(token)).then(getData)

  updateEmployee = async (token: string, form: FormData, id: number): Promise<ApiEmployee> =>
    this.axios.post(`users/employees/update/${id}`, form, this.authConfig(token)).then(getData)

  getRoles = async (token: string): Promise<Role[]> => this.axios.get(`/roles/employees`, this.authConfig(token))

  getEmployeeTracking = async (token: string, parkId: number, type: 1 | 2) =>
    this.axios.get(`/users/employees/tracking?park_id=${parkId}&type=${type}`, this.authConfig(token)).then(getData)

  createEmployeeTracking = async (token: string, formData: FormData) =>
    this.axios.post(`/users/employees/tracking/create`, formData, this.authConfig(token)).then(getData)

  deleteEmployeeTrackingById = async (token: string, id: number) =>
    this.axios.delete(`/users/employees/tracking/${id}/delete`, this.authConfig(token)).then(getData)

  editEmployeeTrackingById = async (token: string, id: number, string: string) =>
    this.axios
      .patch(`/users/employees/tracking/${id}/update?${string}`, undefined, this.authConfig(token))
      .then(getData)

  getEmployeeReportsById = async (token: string, id: number, from: string, to: string) =>
    this.axios.get(`/users/employees/${id}/tracking?from=${from}&to=${to}`, this.authConfig(token)).then(getData)

  getSales = async (token: string, parkId: number, deleted: number): Promise<ApiSale[]> =>
    this.axios.get(`/sale?park_id=${parkId}&deleted=${deleted}`, this.authConfig(token)).then(getData)

  getAdminSales = async (token: string): Promise<ApiSale[]> =>
    this.axios.get(`/sale/group`, this.authConfig(token)).then(getData)

  getSaleById = async (token: string, id: number): Promise<ApiSale> =>
    this.axios.get(`/sale/${id}`, this.authConfig(token)).then(getData)

  createSale = async (token: string, formData: FormData) =>
    this.axios.post(`/sale/create`, formData, this.authConfig(token)).then(getData)

  createAdminSale = async (token: string, formData: FormData) =>
    this.axios.post(`/sale/group/create`, formData, this.authConfig(token)).then(getData)

  updateSale = async (token: string, query: string, id: number) =>
    this.axios.patch(`/sale/${id}/update${query}`, undefined, this.authConfig(token)).then(getData)

  updateAdminSale = async (token: string, query: string, id: number) =>
    this.axios.patch(`/sale/group/${id}/update${query}`, undefined, this.authConfig(token)).then(getData)

  deleteSale = async (token: string, id: number): Promise<ApiSale> =>
    this.axios.delete(`/sale/${id}/delete`, this.putConfig(token)).then(getData)

  getNewsletter = async (token: string, parkId: number, deleted: number): Promise<ApiNewsLetter[]> =>
    this.axios.get(`/distribution?park_id=${parkId}&deleted=${deleted}`, this.authConfig(token)).then(getData)

  getAdminNewsletter = async (token: string): Promise<ApiNewsLetter[]> =>
    this.axios.get(`/distribution/group`, this.authConfig(token)).then(getData)

  getNewsletterById = async (token: string, id: number): Promise<ApiNewsLetter> =>
    this.axios.get(`/distribution/${id}`, this.authConfig(token)).then(getData)

  getNewsletterStateById = async (token: string, id: number) =>
    this.axios.get(`/distribution/${id}/state`, this.authConfig(token)).then(getData)

  createNewsletter = async (token: string, formData: FormData) =>
    this.axios.post(`/distribution/create`, formData, this.authConfig(token)).then(getData)

  createAdminNewsletter = async (token: string, formData: FormData) =>
    this.axios.post(`/distribution/group/create`, formData, this.authConfig(token)).then(getData)

  updateNewsletter = async (token: string, query: string, id: number) =>
    this.axios.patch(`/distribution/${id}/update${query}`, undefined, this.authConfig(token)).then(getData)

  updateAdminNewsletter = async (token: string, query: string, id: number) =>
    this.axios.patch(`/distribution/group/${id}/update${query}`, undefined, this.authConfig(token)).then(getData)

  deleteNewsletter = async (token: string, id: number) =>
    this.axios.delete(`/distribution/${id}/delete`, this.putConfig(token)).then(getData)

  sendPin = async (orderId: number, token: string) =>
    this.axios.post(`/order/${orderId}/pin/generate`, undefined, this.authConfig(token)).then(getData)

  agreementConfirm = async (orderId: number, token: string) =>
    this.axios.post(`/order/${orderId}/agreement/confirm`, undefined, this.authConfig(token)).then(getData)

  checkPin = async (orderId: number, formData: FormData, token: string) =>
    this.axios.post(`/order/${orderId}/pin/check`, formData, this.authConfig(token)).then(getData)

  generateLink = async (id: number, form: FormData, token: string) =>
    this.axios.post(`/order/admin/${id}/link/generate`, form, this.authConfig(token)).then(getData)

  getDiscountById = async (token: string, id: number): Promise<ApiDiscount> =>
    this.axios.get(`/discount/${id}`, this.authConfig(token)).then(getData)

  createAdminDiscount = async (token: string, formData: FormData) =>
    this.axios.post(`/discount/group/create`, formData, this.authConfig(token)).then(getData)

  createDiscout = async (token: string, formData: FormData) =>
    this.axios.post(`/discount/create`, formData, this.authConfig(token)).then(getData)

  updateDiscount = async (token: string, query: string, id: number) =>
    this.axios.patch(`/discount/${id}/update${query}`, undefined, this.authConfig(token)).then(getData)

  updateAdminDiscount = async (token: string, query: string, id: number) =>
    this.axios.patch(`/discount/group/${id}/update${query}`, undefined, this.authConfig(token)).then(getData)

  getDiscounts = async (token: string, parkId: number, deleted: number): Promise<ApiDiscount[]> =>
    this.axios.get(`/discount?park_id=${parkId}&deleted=${deleted}`, this.authConfig(token)).then(getData)

  getAdminDiscounts = async (token: string): Promise<ApiDiscount[]> =>
    this.axios.get(`/discount/group`, this.authConfig(token)).then(getData)

  deleteDiscount = async (token: string, id: number) =>
    this.axios.delete(`/discount/${id}/delete`, this.putConfig(token)).then(getData)

  loadTotal = async (token: string, parkId: number, dateFrom?: string, dateTo?: string) =>
    this.axios
      .get(
        `/admin/payment/total?park_id=${parkId}${dateFrom ? `&created_from=${dateFrom}` : ''}${
          dateTo ? `&created_to=${dateTo}` : ''
        }`,
        this.authConfig(token)
      )
      .then(getData)
}

export const getData = <T>(response: AxiosResponse<T>) => response.data

import { ApiTicket } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { useStore } from '@hooks/storeHook'
import { TicketPaymentFormType } from '@modules/Reception/types'
import times from '@utils/times'
import { default as classNames, default as cn } from 'classnames'
import { observer } from 'mobx-react-lite'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { IDisabled } from '../Form'

export const ServicesPart: FC<IDisabled> = observer(({ disable }) => {
  const { control, setValue, trigger } = useFormContext<TicketPaymentFormType>()
  const [date, startsAt, endsAt] = useWatch({ control: control, name: ['date', 'starts_at', 'ends_at'] })

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: 'services',
  })

  const {
    advertising: { discounts },
    services: { crmServices },
  } = useStore()

  const serviceBodyTemplate = (rowIndex: number) => {
    return (
      <Dropdown
        value={fields[rowIndex].entity}
        onChange={(e) => {
          update(rowIndex, {
            ...fields[rowIndex],
            entity: e.value,
            totalPrice: calcPriceToDiscount(rowIndex, e.value.price),
          })
          if (!fields[rowIndex].entity) append({ entity: null, totalPrice: 0, discount: null })
        }}
        options={crmServices.filter((e) => +e.price > 0)}
        panelClassName='worker-panel'
        color='green'
        optionLabel='name'
        placeholder='Выберите билет'
        className={classNames(`w-full md:w-14rem border-green worker h-[36px] flex items-center`)}
        disabled={disable}
      />
    )
  }

  const dateBodyTemplate = () => {
    return (
      <Controller
        control={control}
        name='date'
        rules={{ required: startsAt || endsAt ? 'Date is required.' : undefined }}
        render={({ fieldState }) => (
          <Calendar
            value={date ?? undefined}
            onChange={(e) => {
              setValue('date', e.value ?? null)
              trigger('date')
            }}
            placeholder='дата'
            showIcon={true}
            dateFormat='dd.mm.yy'
            locale='ru'
            readOnlyInput={true}
            className='worker shadow-none w-[120px]'
            inputClassName={cn('shadow-none', fieldState.error && '!border-red')}
            disabled={disable || !fields.some((e) => e.entity !== null)}
          />
        )}
      />
    )
  }

  const timeBodyTemplate = () => {
    return (
      <div className='flex gap-1 items-center'>
        <Controller
          control={control}
          name='starts_at'
          rules={{ required: date || endsAt ? 'Time is required.' : undefined }}
          render={({ fieldState }) => (
            <Dropdown
              value={startsAt}
              placeholder='от'
              className={cn(
                '!w-[62px] border-green worker text-red timeInput h-[36px]',
                fieldState.error && '!border-red'
              )}
              panelClassName='worker-panel'
              color='green'
              options={times}
              onChange={(e) => {
                setValue('starts_at', e.value)
                trigger('starts_at')
              }}
              disabled={disable || !fields.some((e) => e.entity !== null)}
            />
          )}
        />
        <p>—</p>
        <Controller
          control={control}
          name='ends_at'
          rules={{ required: date || startsAt ? 'Date is required.' : undefined }}
          render={({ fieldState }) => (
            <Dropdown
              value={endsAt}
              className={cn('!w-[62px] border-green worker timeInput h-[36px]', fieldState.error && '!border-red')}
              placeholder='до'
              panelClassName='worker-panel'
              color='green'
              options={times.filter((e) => e > startsAt)}
              onChange={(e) => {
                setValue('ends_at', e.value)
                trigger('ends_at')
              }}
              disabled={disable || !fields.some((e) => e.entity !== null)}
            />
          )}
        />
      </div>
    )
  }

  const discountBodyTemplate = (rowIndex: number) => {
    if (!fields[rowIndex].entity) return null

    return (
      <Dropdown
        optionLabel='name'
        placeholder='Выберите скидку'
        options={discounts}
        showClear
        value={fields[rowIndex].discount}
        onChange={(e) => {
          if (e.value)
            update(rowIndex, {
              ...fields[rowIndex],
              discount: e.value,
              totalPrice: calcDiscountToPrice(rowIndex, e.value.amount, e.value.type),
            })
          else
            update(rowIndex, {
              ...fields[rowIndex],
              discount: null,
              totalPrice: +fields[rowIndex].entity!.price,
            })
        }}
        panelClassName='worker-panel'
        color='green'
        emptyMessage='Не найдено 😢'
        className={classNames(`w-full md:w-14rem border-green worker h-[36px] flex items-center`)}
        disabled={disable}
      />
    )
  }

  const calcDiscountToPrice = (i: number, amount: number, type: number) => {
    if (!fields[i] || !fields[i]?.entity?.price) return 0

    const price = +fields[i]!.entity!.price

    let res = 0
    if (amount && type === 1) {
      res = price - amount
    } else if (amount && type === 2) {
      res = price - Math.ceil((price * amount) / 100)
    } else {
      res = price
    }

    return Math.max(1, res)
  }

  const calcPriceToDiscount = (i: number, price: number) => {
    const discountValue = fields[i].discount?.amount
    const discountType = fields[i].discount?.type

    let res = 0
    if (discountValue && discountType === 1) {
      res = price - +discountValue
    } else if (discountValue && discountType === 2) {
      res = price - Math.ceil((price * +discountValue) / 100)
    } else {
      res = price
    }

    return Math.max(1, res)
  }

  const deleteRowTemplate = (i: number) => {
    const removeHandler = () => {
      if (fields.length === 2) {
        setValue('date', null)
        setValue('starts_at', '')
        setValue('ends_at', '')
      }
      remove(i)
    }

    if (!fields[i].entity) return null

    return (
      <UiButton color='red' onClick={removeHandler} type='button'>
        <i className='pi pi-trash !text-white'></i>
      </UiButton>
    )
  }

  const sumBodyTemplate = (i: number) => {
    if (!fields[i].entity) return null

    return <UiText className='font-bold'>{fields[i].totalPrice}</UiText>
  }

  const textDiscountBodyTemplate = (i: number) => {
    if (!fields[i].entity) return null

    return <UiText className='font-bold'>{fields[i].discount?.amount ?? 0}</UiText>
  }

  const priceBodyTemplate = (i: number) => {
    if (!fields[i].entity) return null

    return <UiText className='font-bold'>{fields[i].entity?.price}</UiText>
  }

  return (
    <>
      <header className='flex items-center gap-4'>
        <UiText size='lg' weight='bold'>
          Услуги
        </UiText>

        {dateBodyTemplate()}
        {timeBodyTemplate()}
      </header>
      <section className='flex flex-col gap-5'>
        <DataTable
          value={fields}
          showGridlines
          size={'small'}
          filterLocale='ru'
          className='bg-white text-base ui-paginator-table'
          style={{ fontSize: '12px' }}
          removableSort
          emptyMessage='Услуги не найдены'
          scrollable
          scrollHeight='flex'
          dataKey='id'
        >
          <Column
            field='name'
            header='Услуга'
            body={(e: ApiTicket, options) => serviceBodyTemplate(options.rowIndex)}
          />
          <Column header='Цена' body={(_, props) => priceBodyTemplate(props.rowIndex)} />
          <Column header='Тип скидки' body={(_, props) => discountBodyTemplate(props.rowIndex)} />
          <Column header='Сумма скидки' body={(_, props) => textDiscountBodyTemplate(props.rowIndex)} />
          <Column header='Итого' body={(_, props) => sumBodyTemplate(props.rowIndex)} />
          {!disable && (
            <Column header='' body={(_, props) => deleteRowTemplate(props.rowIndex)} className='max-w-[39px]' />
          )}
        </DataTable>
      </section>
    </>
  )
})

//@ts-nocheck

import { ApiPayment, ApiUser } from '@@types/apiLogicTypes'
import { phoneTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths } from '@config/navigation'
import { useLogic, useStore } from '@hooks/storeHook'
import { parseTimeFromText } from '@utils/times'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable, DataTableStateEvent, DataTableValueArray } from 'primereact/datatable'
import { FC, useEffect, useRef, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

export const Table: FC<ITable> = observer(() => {
  const logic = useLogic()
  const navigate = useNavigate()

  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const calendar = useRef<Calendar>()
  const [selectedSales, setSelectedSales] = useState<DataTableValueArray>()
  const [cashSum, setCashSum] = useState<number>(0)
  const [onlineSum, setOnlineSum] = useState<number>(0)
  const [lazyState, setlazyState] = useState<Required<DataTableStateEvent>>({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.BETWEEN },
      created_at: {
        operator: FilterOperator.AND,
        constraints: [{ value: [new Date(), new Date()], matchMode: FilterMatchMode.DATE_IS }],
      },
    },
  })

  const {
    payments: { payments },
    auth: { selectedPark },
  } = useStore()

  const loadLazyData = async () => {
    setIsLoading(true)
    const response = await logic.loadPayments(
      lazyState.page + 1,
      lazyState.filters?.created_at?.constraints[0]?.value?.[0] &&
        moment(lazyState.filters.created_at.constraints[0].value[0]).format('YYYY-MM-DD'),
      lazyState.filters?.created_at?.constraints[0]?.value?.[1] &&
        moment(lazyState.filters.created_at.constraints[0].value[1]).format('YYYY-MM-DD')
    )
    if (response?.data) {
      setTotalRecords(response?.data.data.total)
      setCashSum(response?.data.data.cash)
      setOnlineSum(response?.data.data.terminal)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadLazyData()
  }, [selectedPark, lazyState])

  const dateBodyTemplate = (rowData: ApiUser) => {
    return moment(rowData.created_at).format('DD.MM.YYYY')
  }

  const onPage = (props: Required<DataTableStateEvent>) => {
    setlazyState(props)
  }

  const onSort = (props: Required<DataTableStateEvent>) => {
    console.log(props)
  }

  const onFilter = (props: Required<DataTableStateEvent>) => {
    props['first'] = 0
    setlazyState(props)
  }

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        ref={calendar}
        autoFocus={false}
        selectionMode='range'
        value={options.value}
        onChange={(e) => {
          e.value[1] && calendar.current.hide()
          options.filterCallback(e.value, options.index)
        }}
        dateFormat='mm/dd/yy'
        placeholder='Период'
        locale='ru'
        maxDate={new Date()}
      />
    )
  }

  const durationTemplate = (e: ApiPayment) => {
    if (!e.order.tickets[0]?.unit?.text) return '-'

    const diff = calcDiff(e)

    return <span className={diff < 0 && diff > -30 ? '!text-red' : ''}>{diff > 0 ? diff : 0}</span>
  }

  const childTemplate = (e: ApiPayment) => {
    return <span>{e.order.children.map((e) => e.name).join(', ')}</span>
  }

  const onRowDoubleClickHandler = (e: ApiPayment) => {
    if (!e.order.holiday) {
      const path = generatePath(AppRoutesPaths.TicketSaleEdit, {
        id: String(e.id),
      })

      navigate(path)
      return
    }
    const path = generatePath(AppRoutesPaths.ConstructorEdit, {
      id: String(e.order_id),
    })

    navigate(path)
  }

  const statusTemplate = (e: ApiPayment) => {
    switch (e.status) {
      case 1:
        return <span>Не оплачен</span>
      case 2:
        if (e.order?.status === 5) return <span>Предоплачен</span>
        return <span>Успешный</span>
      case 3:
        return <span>Ошибка</span>
      case 4:
        return <span>Возврат</span>
    }
  }

  const sumTemplate = (e: ApiPayment) => {
    if (e.order?.status === 5) return <span>{e.sum + ' из ' + e.order?.price}</span>
    return <span>{e.sum}</span>
  }

  return (
    <section className='flex flex-col gap-5 relative overflow-hidden pb-[80px]'>
      <div className='w-fit ml-auto flex gap-9 min-h-[36px] items-center'>
        <UiText className='text-grey'>
          Нал:{' '}
          <span className=' border-grey-secondary border-solid border rounded-[3px] px-2 py-[7px] ml-[5px]'>
            {cashSum}
          </span>
        </UiText>
        <UiText className='text-grey'>
          Безнал:
          <span className=' border-grey-secondary border-solid border rounded-[3px] px-2 py-[7px] ml-[5px]'>
            {onlineSum}
          </span>
        </UiText>
        <UiText className='text-grey '>
          Гостей:
          <span className=' border-grey-secondary border-solid border rounded-[3px] px-2 py-[7px] ml-[5px]'>
            {totalRecords}
          </span>
        </UiText>
      </div>
      <DataTable
        lazy
        totalRecords={totalRecords}
        onPage={onPage}
        filters={lazyState.filters}
        globalFilterFields={['created_at']}
        onSort={onSort}
        onFilter={onFilter}
        value={payments}
        loading={isLoading}
        showGridlines
        size={'small'}
        filterLocale='ru'
        selectionMode='checkbox'
        selection={selectedSales}
        onSelectionChange={(e) => setSelectedSales(e.value)}
        className={cn('bg-white text-base', 'ui-paginator-table')}
        style={{ fontSize: '12px' }}
        removableSort
        sortField={lazyState.sortField}
        first={lazyState.first}
        sortOrder={lazyState.sortOrder}
        emptyMessage='Платежи не найдены'
        paginator
        rows={20}
        scrollable
        scrollHeight='flex'
        onRowDoubleClick={(e) => onRowDoubleClickHandler(e.data)}
        rowClassName={(e) => {
          const diff = calcDiff(e)
          let classname = diff < 0 && diff > -30 ? 'bg-bg-red cursor-pointer' : 'cursor-pointer'
          switch (e.status) {
            case 1:
              classname += ' bg-bg-blue'
              break
            case 2:
              if (e.order.status === 5) classname += ' bg-bg-violet'
              else classname += ' bg-bg-green'
              break
            case 3:
              classname += ' bg-bg-red'
              break
            case 4:
              classname += ' bg-bg-yellow'
              break
          }
          return classname
        }}
      >
        {/* <Column selectionMode='multiple' headerStyle={{ width: '3rem' }}></Column> */}
        <Column
          filterField='created_at'
          header='Дата'
          dataType='date'
          body={dateBodyTemplate}
          filterMatchMode={FilterMatchMode.BETWEEN}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filter
          filterElement={dateFilterTemplate}
          filterApply={(e) => (
            <UiButton onClick={e.filterApplyCallback} className='capitalize'>
              Принять
            </UiButton>
          )}
          filterClear={(e) => (
            <UiButton
              onClick={e.filterClearCallback}
              className='capitalize !border border-solid border-blue'
              color='blue'
            >
              Очистить
            </UiButton>
          )}
        />
        <Column field='status' header='Статус' body={statusTemplate} />
        <Column field='order.id' header='Номер' />
        <Column field='order.user.name' header='Гость' />
        <Column header='Телефон' body={(e) => phoneTemplate(e.order.user)} />
        <Column field='order.price' header='Сумма' body={(e) => sumTemplate(e)} />
        <Column field='order.tickets' header='Время, мин' body={durationTemplate} />
        <Column field='order.children' header='Дети' body={childTemplate} />
      </DataTable>
    </section>
  )
})

export const calcDiff = (e: ApiPayment) => {
  if (!e.order.tickets[0]?.unit?.text) return
  const duration = parseTimeFromText(e.order.tickets[0]?.unit?.text)
  const timeLeft = moment(e.created_at).add(duration, 'm')

  const serverTimezone = 'Europe/Moscow'
  const localTimezone = moment.tz.guess()
  const serverMoment = moment.tz(timeLeft, serverTimezone)
  const localEndsAtObj = serverMoment.clone().tz(localTimezone)

  const diff = localEndsAtObj.diff(moment(), 'm')

  return diff
}

//@ts-nocheck

import { ApiCashReport } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable, DataTableStateEvent, DataTableValueArray } from 'primereact/datatable'
import { FC, useContext, useEffect, useRef, useState } from 'react'

interface ITable {
  setInitialReportHandler: (report: ApiCashReport) => void
}

export const Table: FC<ITable> = observer(({ setInitialReportHandler }) => {
  const logic = useLogic()
  const {
    auth: { selectedPark, user },
    payments: { cashboxSum, cashReports },
  } = useStore()
  const context = useContext(ToastContext)

  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const calendar = useRef<Calendar>()
  const [selectedReports, setSelectedReports] = useState<DataTableValueArray>()
  const [lazyState, setlazyState] = useState<Required<DataTableStateEvent>>({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.BETWEEN },
      created_at: {
        operator: FilterOperator.AND,
        constraints: [{ value: [new Date(), new Date()], matchMode: FilterMatchMode.DATE_IS }],
      },
    },
  })

  const loadLazyData = async () => {
    setIsLoading(true)
    const response = await logic.loadCashReports(
      lazyState.page + 1,
      lazyState.filters?.created_at?.constraints[0]?.value?.[0] &&
        moment(lazyState.filters.created_at.constraints[0].value[0]).format('YYYY-MM-DD'),
      lazyState.filters?.created_at?.constraints[0]?.value?.[1] &&
        moment(lazyState.filters.created_at.constraints[0].value[1]).format('YYYY-MM-DD')
    )
    if (response?.data) {
      setTotalRecords(response?.data.total)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadLazyData()
  }, [selectedPark, lazyState])

  const fetchCashboxSum = async () => {
    await logic.getCashboxSum()
  }

  useEffect(() => {
    if (!selectedPark) return
    fetchCashboxSum()
  }, [selectedPark])

  const onFilter = (props: Required<DataTableStateEvent>) => {
    props['first'] = 0
    setlazyState(props)
  }

  const onPage = (props: Required<DataTableStateEvent>) => {
    setlazyState(props)
  }

  const onSort = (props: Required<DataTableStateEvent>) => {
    console.log(props)
  }

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        ref={calendar}
        autoFocus={false}
        selectionMode='range'
        value={options.value}
        onChange={(e) => {
          e.value[1] && calendar.current.hide()
          options.filterCallback(e.value, options.index)
        }}
        dateFormat='mm/dd/yy'
        placeholder='Период'
        locale='ru'
        maxDate={new Date()}
      />
    )
  }

  const dateBodyTemplate = (rowData: any) => {
    return moment(rowData.created_at).format('DD.MM.YYYY')
  }

  const deleteTemplate = (rowData: any) => {
    if (rowData.type !== 3 && rowData.type !== 4) return null

    const deleteHandler = async () => {
      await logic.deleteCashReport(rowData)
    }

    return (
      <UiButton onClick={deleteHandler} color='red'>
        <i className='!text-white pi pi-trash'></i>
      </UiButton>
    )
  }

  const statusBodyTemplate = (rowData: ApiCashReport) => {
    const onProceed = async () => {
      const response = await logic.minusCashReport(rowData.id)
      if (!response?.status) context?.toastShowHandler({ status: false, errors: response.errors })
    }

    if (user?.roles.some((e) => e.name === 'manager')) {
      if (rowData.status === 1)
        return (
          <UiText className='font-medium underline' onClick={onProceed}>
            Провести
          </UiText>
        )
      else return <UiText className='!text-green font-medium'>Проведен</UiText>
    } else {
      if (rowData.status === 1) return <UiText className='font-medium text-grey'>Не проведен</UiText>
      else return <UiText className='!text-green font-medium'>Проведен</UiText>
    }
  }

  return (
    <section className='flex flex-col gap-5 overflow-hidden pb-[80px] relative -top-[60px]'>
      <div className='w-fit ml-auto flex gap-9 min-h-[36px] items-center'>
        <UiText className='text-grey '>
          Нал:
          <span className=' border-grey-secondary border-solid border rounded-[3px] px-2 py-[7px] ml-[5px]'>
            {cashboxSum}
          </span>
        </UiText>
      </div>
      <DataTable
        lazy
        totalRecords={totalRecords}
        onPage={onPage}
        filters={lazyState.filters}
        globalFilterFields={['created_at']}
        onSort={onSort}
        onFilter={onFilter}
        value={cashReports}
        loading={isLoading}
        showGridlines
        size={'small'}
        filterLocale='ru'
        selectionMode='checkbox'
        selection={selectedReports}
        onSelectionChange={(e) => setSelectedReports(e.value)}
        className='bg-white text-base ui-paginator-table h-fit'
        removableSort
        sortField={lazyState.sortField}
        first={lazyState.first}
        sortOrder={lazyState.sortOrder}
        emptyMessage='Платежи не найдены'
        paginator
        rows={20}
        scrollable
        scrollHeight='flex'
        onRowDoubleClick={(e) => setInitialReportHandler(e.data)}
      >
        <Column
          filterField='created_at'
          header='Дата'
          dataType='date'
          body={dateBodyTemplate}
          filterMatchMode={FilterMatchMode.BETWEEN}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filter
          filterElement={dateFilterTemplate}
          filterApply={(e) => (
            <UiButton onClick={e.filterApplyCallback} className='capitalize'>
              Принять
            </UiButton>
          )}
          filterClear={(e) => (
            <UiButton
              onClick={e.filterClearCallback}
              className='capitalize !text-blue !border border-solid border-blue'
            >
              Очистить
            </UiButton>
          )}
        ></Column>
        <Column field='id' header='Номер'></Column>
        <Column field='name' header='Название'></Column>
        <Column field='type' header='Тип' body={(e) => actionNameSwitcher(e.type)}></Column>
        <Column field='sum' header='Сумма, ₽'></Column>
        <Column field='status' header='Статус' body={(e) => statusBodyTemplate(e)} />
        <Column body={(e) => deleteTemplate(e)} />
      </DataTable>
    </section>
  )
})

const actionNameSwitcher = (type: 1 | 2 | 3 | 4) => {
  switch (type) {
    case 1:
      return 'Продажа'
    case 2:
      return 'Инкассация'
    case 3:
      return 'Авансовый отчет'
    case 4:
      return 'РКО'
  }
}

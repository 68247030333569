import {
  ApiCashReport,
  ApiCreateCategory,
  ApiCreateTicketForm,
  ApiFormCreateHoliday,
  ApiObjPark,
  ApiServicesCreateForm,
  ApiValidationResponse,
  RoleNameEnum,
} from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { VisibleError } from '@common/visibleError'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { Store } from '@store/store'
import { action, observable } from 'mobx'
import { actionAsync, task } from 'mobx-utils'
import { ApiLogic } from './apiLogic'

import { createNestedArray } from '@utils/createDataTreePrimereact'
import { objectToSearchParams } from '@utils/objectToSearchParams'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

export class Logic {
  @observable
  protected store: Store

  private readonly api: ApiLogic

  private static readonly AuthTokenStoreKey = 'auth-token'
  private static readonly AuthLocationStoreKey = 'auth-location'
  private navigateToAuth = () => navigateTo(AppRoutesPaths.Authorization)
  private navigateToMain = () => navigateTo(AppRoutesPaths.Reception)

  constructor(store: Store) {
    this.store = store

    this.api = new ApiLogic()
  }
  private saveTokenUser = (token: string) => {
    localStorage.setItem(Logic.AuthTokenStoreKey, token)
  }
  private removeTokenUser = () => {
    localStorage.removeItem(Logic.AuthTokenStoreKey)
  }
  protected getAuthData = () => {
    const location = localStorage.getItem(Logic.AuthLocationStoreKey)

    if (!this.store.auth.token) {
      this.logOutApp()
      throw new VisibleError('Пожалуйста, пройдите авторизацию.')
    }
    return { token: this.store.auth.token, location }
  }
  private resetAuthData = () => {
    this.removeTokenUser()
  }

  @actionAsync
  loadInitialApp = async () => {
    this.store.rootLoading = LoadState.Loading
    this.loadPersistentToken()

    try {
      await this.loadProfile()

      this.store.rootLoading = LoadState.Success
    } catch (e) {
      this.store.rootLoading = LoadState.Error
    }
  }

  loadApp = async () => {
    try {
      await this.loadServicesMeasures()
      await this.loadServicesUnits()
      await this.loadServicesTypes()
      await this.loadTicketsTypes()
      await this.loadApplications()
      await this.loadRooms()
      await this.loadShowsServices()
      await this.loadEntertaimentProgramsServices()
      await this.loadProduct()
      this.store.rootLoading = LoadState.Success
    } catch (e) {
      this.store.rootLoading = LoadState.Error
    }
  }

  @action
  logOutApp = () => {
    this.resetAuthData()
    this.navigateToAuth()
  }

  @actionAsync
  loadProfile = async () => {
    try {
      await this.loadUser()
    } catch (e) {
      this.logOutApp()
      return
    }
  }

  @actionAsync
  private processLogIn = async (token: string, isPersistent: boolean): Promise<void> => {
    if (isPersistent) {
      this.saveTokenUser(token)
    }

    this.store.auth.token = token

    try {
      await task(this.loadUser())
    } catch (e: unknown) {
      await task(this.logOutApp())

      throw new VisibleError('Не удалось загрузить данные пользователя', e)
    }
  }

  @actionAsync
  loadPersistentToken = () => {
    const token = localStorage.getItem(Logic.AuthTokenStoreKey)
    if (!token) {
      return
    }
    this.store.auth.token = token
  }

  @actionAsync
  successAuth = async (token: string) => {
    await this.processLogIn(token, true)

    this.navigateToMain()
  }

  @actionAsync
  loadUser = async () => {
    if (!this.store.auth.token) throw new VisibleError('No token!')

    try {
      const user = await task(this.api.loadUser(this.store.auth.token))
      const roles = user.roles.map((item) => item.name)
      this.store.auth.user = user
      this.store.auth.roles = [...roles, RoleNameEnum.default]
      if (roles.includes(RoleNameEnum.manager)) {
        this.initialManager()
      }
      if (roles.includes(RoleNameEnum.superadmin)) {
        this.initialSuperAdmin()
      }
      if (roles.includes(RoleNameEnum.animator)) {
        this.initialGameManager()
      }
    } catch (e) {
      this.resetAuthData()
    }
  }

  @actionAsync
  initialSuperAdmin = async () => {
    await this.loadParks()

    const parkId = localStorage.getItem('parkId')

    const chosenPark = this.store.park.parks.find((e) => String(e.id) === parkId) || this.store.park.parks[0]
    this.store.auth.choisePark(chosenPark)

    this.store.auth.availableParks = this.store.park.parks

    this.loadApp()
  }
  @action
  initialManager = () => {
    const parks = this.store.auth.user?.parks ?? []

    const parkId = localStorage.getItem('parkId')

    const chosenPark = parks.find((e) => String(e.id) === parkId) || parks[0]

    this.store.auth.choisePark(chosenPark)

    this.loadServicesTypes()
    this.store.auth.availableParks = parks

    this.loadApp()
  }

  @action
  initialGameManager = () => {
    const parks = this.store.auth.user?.parks ?? []

    const parkId = localStorage.getItem('parkId')

    const chosenPark = parks.find((e) => String(e.id) === parkId) || parks[0]

    this.store.auth.choisePark(chosenPark)

    this.store.auth.availableParks = parks

    this.loadApp()
  }

  @actionAsync
  getAvailableParks = async () => {
    if (!this.store.auth.token) throw new VisibleError('No token!')
    try {
      const user = await task(this.api.loadUser(this.store.auth.token))
      const roles = user.roles.map((item) => item.name)
      if (roles.includes(RoleNameEnum.manager)) {
        this.store.auth.availableParks = user.parks
      }
      if (roles.includes(RoleNameEnum.superadmin)) {
        await task(this.loadParks())
      }
    } catch (e) {
      //
    }
  }

  @actionAsync
  onSendAuthorization = async (email: string, password: string) => {
    this.store.rootLoading = LoadState.Loading

    try {
      const token = await task(this.api.postAuthorization({ email, password }))

      this.successAuth(token)
      this.store.rootLoading = LoadState.Success
    } catch (e) {
      this.store.rootLoading = LoadState.Success
    }
  }

  @actionAsync
  loadParks = async () => {
    this.store.park.mainLoading = LoadState.Loading
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getParks(token))

      this.store.park.parks = response

      this.store.park.mainLoading = LoadState.Success
    } catch (e) {
      this.store.park.mainLoading = LoadState.Error
    }
  }
  @actionAsync
  loadСities = async () => {
    const { token } = this.getAuthData()
    if (this.store.park.cities) {
      return
    }
    try {
      const response = await task(this.api.getCities(token))

      this.store.park.cities = response

      this.store.park.mainLoading = LoadState.Success
    } catch (e) {
      this.store.park.mainLoading = LoadState.Error
    }
  }
  @actionAsync
  addPark = async (form: ApiObjPark) => {
    this.store.park.addPark = LoadState.Loading
    const { token } = this.getAuthData()

    try {
      await task(this.api.postAddPark(form, token))

      this.store.park.addPark = LoadState.Success
      return true
    } catch (e) {
      this.store.park.addPark = LoadState.Error
      return false
    }
  }

  @actionAsync
  editPark = async (form: ApiObjPark, id: number) => {
    this.store.park.addPark = LoadState.Loading
    const { token } = this.getAuthData()

    try {
      const park = await task(this.api.editPark(form, token, id))
      this.store.auth.editPark(park)
      this.store.park.addPark = LoadState.Success
      return true
    } catch (e) {
      this.store.park.addPark = LoadState.Error
      return false
    }
  }
  @actionAsync
  editCenter = async (form: FormData, id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    try {
      const park = await task(this.api.editPark(form, token, id))
      this.store.auth.choisePark(park)

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadCategory = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getCategory(token))
      this.store.category.categories = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadCategoryByParkId = async () => {
    const { token } = this.getAuthData()
    const parkId = this.store.auth.selectedPark?.id

    if (!parkId) {
      return
    }
    this.store.category.loadingDatathree = LoadState.Loading
    try {
      const response = await task(this.api.getCategory(token, String(parkId) ?? ''))
      this.store.category.categories = response
      this.store.category.loadingDatathree = LoadState.Success
      return true
    } catch (e) {
      this.store.category.categories = null
      this.store.category.loadingDatathree = LoadState.Success
      return false
    }
  }

  @actionAsync
  loadCategoryById = async () => {
    const { token } = this.getAuthData()
    const id = this.store.auth.selectedPark?.id

    if (!id) {
      return false
    }
    try {
      await task(this.api.getCategoryById(token, id))

      return true
    } catch (e) {
      return false
    }
  }
  @actionAsync
  loadCategoryType = async () => {
    this.store.category.loadingDatathree = LoadState.Loading
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getCategoryType(token))

      this.store.category.types = response
      this.store.category.dataThree = createNestedArray(response, this.store.category.categories ?? [])
      this.store.category.loadingDatathree = LoadState.Success
      return true
    } catch (e) {
      this.store.category.loadingDatathree = LoadState.Error
      return false
    }
  }

  @actionAsync
  createCategory = async (categoryObj: ApiCreateCategory) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.postCreateCategory(token, categoryObj))

      this.store.category.addNewCategory(response)
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  removeCategory = async (id: number) => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.deleteCategory(token, id))
      this.store.category.removeCategory(id)

      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  updateCategory = async (id: string, name: string) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.updateCategory(token, id, name))
      this.store.category.updateCategory(response)

      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadServices = async (deletedFlag?: true) => {
    const selectedPark = this.store.auth.selectedPark
    if (!selectedPark) {
      return
    }
    this.store.services.loadServices = LoadState.Loading
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getServices(token, selectedPark.id, deletedFlag))

      this.store.services.services = response
      this.store.services.loadServices = LoadState.Success
      return true
    } catch (e) {
      this.store.services.loadServices = LoadState.Error
      return false
    }
  }

  @actionAsync
  loadServicesById = async (id: string) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.getServicesById(token, id))

      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadAdminServicesById = async (id: string) => {
    this.store.services.loadById = LoadState.Loading
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getAdminServicesById(token, id))

      this.store.services.selectUpdateServices = response
      this.store.services.loadById = LoadState.Success

      return true
    } catch (e) {
      this.store.services.loadById = LoadState.Success
      return false
    }
  }

  @actionAsync
  loadServicesUnits = async () => {
    if (this.store.services.units) {
      return
    }
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getServicesUnits(token))
      //@ts-ignore
      response.forEach((e) => delete e.value)
      this.store.services.units = response
      return true
    } catch (e) {
      return false
    }
  }
  @actionAsync
  loadServicesMeasures = async () => {
    if (this.store.services.measures) {
      return
    }
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getServicesMeasures(token))
      this.store.services.measures = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadServicesTypes = async () => {
    const id = this.store.auth?.selectedPark?.id
    if (this.store.services.types) {
      return
    }
    if (!id) {
      return
    }

    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getServicesTypes(token, id))
      this.store.services.types = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  createServices = async (form: FormData): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    let response
    try {
      response = await task(this.api.postCreateServices(token, form))

      this.store.services.addService(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteService = async (id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteService(token, id))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response
        ? {
            status: false,
            errors: e.response?.data?.errors || {
              id: [e.response?.data?.message],
            },
          }
        : { status: false }
    }
  }

  @actionAsync
  editServices = async (form: Partial<ApiServicesCreateForm>, id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.putServices(token, id, form))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  editActiveServices = async (form: Partial<ApiServicesCreateForm>, id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.putServices(token, id, form))
      this.store.services.updateService(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadRooms = async (deletedFlag?: boolean) => {
    const selectedPark = this.store.auth.selectedPark
    if (!selectedPark) {
      return
    }

    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.loadRooms(token, selectedPark.id, deletedFlag))

      this.store.rooms.rooms = response
      return true
    } catch (e) {
      return false
    }
  }
  @actionAsync
  loadRoomById = async (id: string) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getRoomById(token, id))

      this.store.rooms.selectedUpdateRoom = response
      return true
    } catch (e) {
      return false
    }
  }
  @actionAsync
  editRoom = async (form: FormData, id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.putUpdateRoom(token ?? '', id, form))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }
  @actionAsync
  editActiveRoom = async (form: FormData, id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.putUpdateRoom(token ?? '', id, form))
      this.store.rooms.updateActiveRoom(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }
  @actionAsync
  createRoom = async (form: FormData): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.postCreateRoom(token ?? '', form))

      this.store.rooms.addRoom(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }
  @actionAsync
  deleteRoom = async (id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteRoom(token, id))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }
  @actionAsync
  loadTicket = async (deletedFlag?: true) => {
    const selectedPark = this.store.auth.selectedPark
    if (!selectedPark) {
      return
    }
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getTicket(token, String(selectedPark.id), deletedFlag))
      this.store.ticket.tickets = response

      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  deleteTicket = async (id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteTicket(token, id))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response
        ? {
            status: false,
            errors: e.response?.data?.errors || {
              id: [e.response?.data?.message],
            },
          }
        : { status: false }
    }
  }

  @actionAsync
  loadTicketById = async (id: string) => {
    const { token } = this.getAuthData()
    this.store.ticket.loadById = LoadState.Loading
    try {
      const response = await task(this.api.getTicketById(token, id))

      this.store.ticket.selectedUpdateTicket = response
      this.store.ticket.loadById = LoadState.Success
      return true
    } catch (e) {
      this.store.ticket.loadById = LoadState.Error
      return false
    }
  }
  @actionAsync
  loadAdminTicketById = async (id: string) => {
    const { token } = this.getAuthData()
    this.store.ticket.loadById = LoadState.Loading
    try {
      const response = await task(this.api.getAdminTicketById(token, id))

      this.store.ticket.selectedUpdateTicket = response
      this.store.ticket.loadById = LoadState.Success
      return true
    } catch (e) {
      this.store.ticket.loadById = LoadState.Error
      return false
    }
  }
  @actionAsync
  loadTicketsTypes = async () => {
    const id = this.store.auth?.selectedPark?.id
    if (this.store.ticket.types) {
      return
    }
    if (!id) {
      return
    }

    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getTicketsTypes(token, id))
      this.store.ticket.types = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  createTicket = async (form: ApiCreateTicketForm): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.postCreateTicket(token, form))
      this.store.ticket.addTicket(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateTicket = async (form: ApiCreateTicketForm): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.putUpdateTicket(token, form))

      this.store.ticket.updateTicket(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateActiveTicket = async (form: ApiCreateTicketForm): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.putUpdateTicket(token, form))

      this.store.ticket.updateActiveTickets(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadAttraction = async (deletedFlag?: true) => {
    const id = this.store.auth?.selectedPark?.id
    if (!id) {
      return
    }

    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getAttraction(token, id, deletedFlag))

      this.store.attraction.attractions = response
      return true
    } catch (e) {
      return false
    }
  }
  @actionAsync
  loadAttractionById = async (id: string) => {
    this.store.attraction.loadById = LoadState.Loading
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getAttractionById(token, id))
      this.store.attraction.selectedUpdateAttraction = response
      this.store.attraction.loadById = LoadState.Success
      return true
    } catch (e) {
      this.store.attraction.loadById = LoadState.Success
      return false
    }
  }
  @actionAsync
  loadAdminAttractionById = async (id: string) => {
    this.store.attraction.loadById = LoadState.Loading
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getAdminAttractionById(token, id))
      this.store.attraction.selectedUpdateAttraction = response
      this.store.attraction.loadById = LoadState.Success
      return true
    } catch (e) {
      this.store.attraction.loadById = LoadState.Success
      return false
    }
  }
  @actionAsync
  createAttraction = async (form: FormData): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.postCreateAttraction(token, form))

      this.store.attraction.addAttraction(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }
  @actionAsync
  deleteAttraction = async (id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteAttration(token, id))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response
        ? {
            status: false,
            errors: e.response?.data?.errors || {
              id: [e.response?.data?.message],
            },
          }
        : { status: false }
    }
  }
  @actionAsync
  updateAttraction = async (form: FormData, id: string): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.postUpdateAttraction(token, form, id))

      this.store.attraction.updateAttraction(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }
  @actionAsync
  loadRoomTypes = async () => {
    const id = this.store.auth?.selectedPark?.id
    if (this.store.rooms.types) {
      return
    }
    if (!id) {
      return
    }

    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getRoomsTypes(token ?? '', id))
      this.store.rooms.types = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadShowsServices = async () => {
    const id = this.store.auth?.selectedPark?.id
    if (!id) {
      return
    }

    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getShowsServices(token ?? '', id))
      this.store.services.showsArray = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadEntertaimentProgramsServices = async () => {
    const id = this.store.auth?.selectedPark?.id
    if (!id) {
      return
    }

    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getEntertaimentProgramsServices(token ?? '', id))
      this.store.services.entertaimentPrograms = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadProduct = async () => {
    const id = this.store.auth?.selectedPark?.id
    if (!id) {
      return
    }
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getProduct(token ?? '', id))
      this.store.product.products = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  createHoliday = async (form: ApiFormCreateHoliday): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    const url = objectToSearchParams(form)
    try {
      const response = await task(this.api.postCreateHoliday(token ?? '', url))
      this.store.holiday.addHoliday(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createAdminHoliday = async (form: ApiFormCreateHoliday): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    const url = objectToSearchParams(form)
    try {
      const response = await task(this.api.postCreateAdminHoliday(token ?? '', url))
      this.store.holiday.addAdminHoliday(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteHoliday = async (id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteHoliday(token, id))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadHolidays = async (deletedFlag?: true) => {
    const id = this.store.auth?.selectedPark?.id
    if (!id) {
      return
    }
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getHolidays(token ?? '', id, deletedFlag))
      this.store.holiday.holidays = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadAdminHolidays = async () => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getAdminHolidays(token ?? ''))
      this.store.holiday.adminHolidays = response
      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  editHoliday = async (form: ApiFormCreateHoliday): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    const url = objectToSearchParams(form)
    try {
      const response = await task(this.api.putUpdateHoliday(token ?? '', url, form.id ?? '-1'))
      this.store.holiday.updateHoliday(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  editAdminHoliday = async (form: ApiFormCreateHoliday): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    const url = objectToSearchParams(form)
    try {
      const response = await task(this.api.putUpdateAdminHoliday(token ?? '', url, form.id ?? '-1'))
      this.store.holiday.updateAdminHoliday(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadHolidayById = async (id: string) => {
    const { token } = this.getAuthData()

    this.store.holiday.loadById = LoadState.Loading
    try {
      const response = await task(this.api.getHolidayById(token, id))

      this.store.holiday.selectedUpdateHoliday = response
      this.store.holiday.loadById = LoadState.Success
      return true
    } catch (e) {
      this.store.holiday.loadById = LoadState.Error
      return false
    }
  }

  @actionAsync
  loadAdminHolidayById = async (id: string) => {
    const { token } = this.getAuthData()

    this.store.holiday.loadById = LoadState.Loading
    try {
      const response = await task(this.api.getAdminHolidayById(token, id))

      this.store.holiday.selectedUpdateHoliday = response
      this.store.holiday.loadById = LoadState.Success
      return true
    } catch (e) {
      this.store.holiday.loadById = LoadState.Error
      return false
    }
  }

  @actionAsync
  createAdminTickets = async (form: ApiCreateTicketForm): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.postCreateTickets(token, form))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadAdminTickets = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminTickets(token))

      this.store.ticket.adminTickets = response

      return true
    } catch (e) {
      return false
    }
  }

  @actionAsync
  loadAdminAttraction = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminAttraction(token))

      this.store.attraction.adminAttraction = response

      return true
    } catch (e) {
      return false
    }
  }
  @actionAsync
  createAdminAttraction = async (form: FormData): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.postCreateAttraction(token, form))

      this.store.attraction.addAdminAttraction(response)

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateAdminTickets = async (form: ApiCreateTicketForm): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.putUpdateAdminTickets(token, form))

      this.store.ticket.updateAdminTicket(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateAdminAttraction = async (form: FormData, id: string): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.postAdminUpdateAttraction(token, form, id))

      this.store.attraction.updateAttractionAdmin(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadAdminServices = async () => {
    this.store.services.loadAdminServices = LoadState.Loading
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminServices(token))

      this.store.services.adminServices = response
      this.store.services.loadAdminServices = LoadState.Success
      return true
    } catch (e) {
      this.store.services.loadAdminServices = LoadState.Error
      return false
    }
  }

  @actionAsync
  loadAdminShowsServices = async () => {
    this.store.services.loadAdminServices = LoadState.Loading
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminShowsServices(token))

      this.store.services.adminShows = response
      this.store.services.loadAdminServices = LoadState.Success
      return true
    } catch (e) {
      this.store.services.loadAdminServices = LoadState.Error
      return false
    }
  }

  @actionAsync
  editAdminService = async (form: FormData, id: string): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.postAdminService(token, id, form))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createAdminService = async (form: FormData): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.postAdminCreateServices(token, form))

      this.store.services.addAdminService(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadApplications = async () => {
    const { token } = this.getAuthData()

    const selectedPark = this.store.auth.selectedPark

    if (!selectedPark) {
      return
    }

    try {
      const requests = await (await task(this.api.getApplications(token))).filter((e) => e.park_id === selectedPark.id)
      this.store.applications.applications = requests

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadApplicationById = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await await task(this.api.getApplicationById(token, id))

      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadScheduleApplications = async (dataFrom: string, dataTo: string) => {
    const { token } = this.getAuthData()
    this.store.applications.loading = LoadState.Loading

    const selectedPark = this.store.auth.selectedPark

    if (!selectedPark) {
      return
    }
    try {
      const requests = await task(this.api.loadSchedule(token, selectedPark.id, dataFrom, dataTo))
      this.store.applications.scheduleApplications = requests.filter(
        (e) => e.order.status === 3 || e.order.status === 4 || e.order.status === 5
      )
      this.store.applications.loading = LoadState.Success
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.applications.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  sendApplication = async (formData: FormData) => {
    try {
      await task(this.api.createApplication(formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  sendAdminApplication = async (formData: FormData) => {
    try {
      const { token } = this.getAuthData()
      const response = await task(this.api.createAdminApplication(token, formData))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  sendNoteForApplication = async (id: number, formData: FormData) => {
    try {
      const { token } = this.getAuthData()
      const response = await task(this.api.addNoteForOrder(id, formData, token))
      return response
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateAdminApplication = async (id: number, formData: FormData) => {
    try {
      const { token } = this.getAuthData()
      const response = await task(this.api.updateApplication(id, formData, token))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateApplicationsByStatus = async (
    status: number,
    applications: { id: number; sort: number }[]
  ): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.updateApplicationsByStatus(token, status, applications))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateApplicationsById = async (id: number, status: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.updateApplicationsById(token, id, status))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateApplicationsByTime = async (id: number, start: string, end: string): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.updateScheduleOrder(token, id, start, end))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteApplicationsById = async (id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteApplicationsById(token, id))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createStory = async (form: FormData): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.createStory(token, form))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createAdminStory = async (form: FormData): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.createAdminStory(token, form))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  getStories = async (id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    this.store.advertising.loading = LoadState.Loading

    try {
      const response = await task(this.api.getStories(token, id))
      this.store.advertising.addStories(response)

      this.store.advertising.loading = LoadState.Success
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.advertising.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  getAdminStories = async (): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()
    this.store.advertising.loading = LoadState.Loading

    try {
      const response = await task(this.api.getAdminStories(token))
      this.store.advertising.stories = [...response]

      this.store.advertising.loading = LoadState.Success
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.advertising.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  editStory = async (form: FormData, id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.updateStory(token ?? '', id, form))
      this.store.advertising.updateStories(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  editAdminStory = async (form: FormData, id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.updateAdminStory(token ?? '', id, form))
      this.store.advertising.updateStories(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteStories = async (id: number): Promise<ApiValidationResponse> => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteStories(token ?? '', id))
      this.store.advertising.deleteStories(id)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  searchUserByPhone = async (phone?: string, query?: string) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.searchUser(token, phone, query))
      return response
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.advertising.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadClients = async (page: number, dateFrom?: string, dateTo?: string) => {
    const { token } = this.getAuthData()
    const parkId = this.store.auth.selectedPark?.id

    if (!parkId) return
    try {
      this.store.users.loading = LoadState.Loading
      const response = await task(this.api.getClients(token, parkId, page, dateFrom, dateTo))
      this.store.users.clients = response.data
      this.store.users.loading = LoadState.Success
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.users.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadClientById = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getClientById(token, id))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  sendNoteForClient = async (id: number, formData: FormData) => {
    try {
      const { token } = this.getAuthData()
      const response = await task(this.api.addNoteForClient(id, formData, token))
      return response
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateClient = async (id: number, formData: FormData) => {
    try {
      const { token } = this.getAuthData()
      const response = await task(this.api.updateClient(id, formData, token))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadAnimators = async () => {
    const { token } = this.getAuthData()
    const parkId = this.store.auth.selectedPark?.id

    if (!parkId) return
    try {
      this.store.users.loading = LoadState.Loading
      const response = await task(this.api.getAnimators(parkId, token))
      this.store.users.animators = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.users.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  sendIntegrationGame = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.sendIntegrationGame(token, formData))
      if (!response.success) throw { status: false, message: response.message }
      return { status: true, message: response.message }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.message ? { status: false, message: e.message } : { status: false }
    }
  }

  @actionAsync
  setAdminGames = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.setAdminGames(token, formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  getIntegrationBirthday = async (parkId: number) => {
    const { token } = this.getAuthData()

    try {
      this.store.game.loading = LoadState.Loading
      const response = await task(this.api.getIntegrationBirthday(token, parkId))
      this.store.game.holidays = response
      this.store.game.loading = LoadState.Success
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.game.loading = LoadState.Error
      return e.message ? { status: false, message: e.message } : { status: false }
    }
  }

  @actionAsync
  createIntegrationBirthday = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.createIntegrationBirthday(token, formData))
      this.store.game.holidays = [...this.store.game.holidays, response]
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.message ? { status: false, message: e.message } : { status: false }
    }
  }

  @actionAsync
  editIntegrationBirthday = async (id: number, formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.editIntegrationBirthday(token, id, formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteIntegrationBirthday = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteIntegrationBirthday(token, id))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  startIntegrationBirthday = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.startIntegrationBirthday(token, id))
      if (!response.success) throw { status: false, message: response.message }
      //@ts-ignore
      response.data && this.store.game.editHoliday(response.data)
      return { status: true, message: response.message }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.message ? { status: false, message: e.message } : { status: false }
    }
  }

  @actionAsync
  stopIntegrationBirthday = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.stopIntegrationBirthday(token, id))
      if (!response.success) throw { status: false, message: response.message }
      //@ts-ignore
      response.data && this.store.game.editHoliday(response.data)
      return { status: true, message: response.message }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.message ? { status: false, message: e.message } : { status: false }
    }
  }

  @actionAsync
  sendIntegrationPC = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.sendIntegrationPC(formData, token))
      if (!response.success) throw { status: false, message: response.message }
      return { status: true, message: response.message }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.message ? { status: false, message: e.message } : { status: false }
    }
  }

  @actionAsync
  loadIntegrationByPark = async () => {
    const parkId = this.store.auth.selectedPark?.id
    if (!parkId) return

    const { token } = this.getAuthData()
    try {
      this.store.game.loading = LoadState.Loading
      const response = await task(this.api.getIntegrationByPark(parkId, token))
      this.store.game.computers = response.computers
      this.store.game.gameZones = response.zones
      this.store.game.games = response.games
      this.store.game.loading = LoadState.Success
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.game.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  getAdminGames = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminGames(token))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  getAdminGamesTypes = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminGamesTypes(token))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteGameZone = async (zoneId: number) => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.deleteGameZone(zoneId, token))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createGameZone = async (formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.createGameZone(formData, token))
      return { status: true, id: response.id }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  editGameZone = async (zoneId: number, formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.updateGameZone(zoneId, formData, token))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createPC = async (formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.createPC(formData, token))
      return { status: true, id: response.id }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deletePC = async (zoneId: number) => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.deletePC(zoneId, token))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  editPC = async (pcID: number, formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.updatePC(pcID, formData, token))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createPaymentTicket = async (formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.createPaymentTicket(formData, token))
      return { status: true, payment: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createPaymentTicketByOrderId = async (id: number, formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.createPaymentTicketByOrderId(formData, id, token))
      return { status: true, payment: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  applyPayment = async (formData: FormData, id: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.applyPayment(formData, id, token))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  checkSetTicket = async (formData: FormData, id: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.checkSetPaymentTicket(formData, id, token))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  errorPaymentTicket = async (formData: FormData, id: number) => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.errorPaymentTicket(formData, id, token))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  refoundPaymentTicket = async (formData: FormData, id: number) => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.refoundPaymentTicket(formData, id, token))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createPaymentHoliday = async (orderId: number, formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.createPaymentHoliday(orderId, formData, token))
      return { status: true, payment: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadPayments = async (page: number, dateFrom?: string, dateTo?: string) => {
    const { token } = this.getAuthData()
    const parkId = this.store.auth.selectedPark?.id

    if (!parkId) return
    try {
      const response = await task(this.api.getPayments(token, parkId, page, dateFrom, dateTo))
      this.store.payments.payments = response.data.data
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.users.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadCashReports = async (page: number, dateFrom?: string, dateTo?: string) => {
    const { token } = this.getAuthData()
    const parkId = this.store.auth.selectedPark?.id

    if (!parkId) return
    try {
      const response = await task(this.api.getCashReports(token, parkId, page, dateFrom, dateTo))
      this.store.payments.cashReports = response.data
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.users.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  minusCashReport = async (id: number) => {
    const { token } = this.getAuthData()
    const parkId = this.store.auth.selectedPark?.id

    if (!parkId) return
    try {
      const response = await task(this.api.minusCashReport(token, id))
      this.store.payments.changeCashboxReport(response.log)
      this.store.payments.changeCashBoxSum(response.balance)
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteCashReport = async (report: ApiCashReport) => {
    const { token } = this.getAuthData()
    const parkId = this.store.auth.selectedPark?.id

    if (!parkId) return
    try {
      const response = await task(this.api.deleteCashReport(token, report.id))
      this.store.payments.removeCashboxReport(report.id)
      report.status === 0 && this.store.payments.changeCashBoxSum(this.store.payments.cashboxSum - report.sum)
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  cashboxMinus = async (formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.cashboxMinus(token, formData))
      return { status: true, balance: response.balance, log: response.log }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.users.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  cashboxCollection = async (formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.cashboxCollection(token, formData))
      return { status: true, balance: response.balance, log: response.log }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.users.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateLogReport = async (id: number, formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.updateLogReport(token, id, formData))
      return { status: true, balance: response.balance, log: response.log }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.users.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  getCashboxSum = async () => {
    const { token } = this.getAuthData()

    const parkId = this.store.auth.selectedPark?.id

    if (!parkId) return
    try {
      const response = await task(this.api.getCashboxSum(token, parkId))
      this.store.payments.cashboxSum = response
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      this.store.users.loading = LoadState.Error
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadPaymentById = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.getPaymentById(token, id))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadEmployees = async () => {
    const selectedPark = this.store.auth.selectedPark
    if (!selectedPark) {
      return
    }
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getEmployees(token, selectedPark.id))
      this.store.users.employees = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadManagers = async (parkId?: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getManagers(token, parkId))
      this.store.users.managers = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadEmployeeById = async (id: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getEmployeeById(token, id))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createEmployee = async (form: FormData) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.postEmployee(token, form))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateEmployee = async (form: FormData, id: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.updateEmployee(token, form, id))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadRoles = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getRoles(token))
      this.store.users.roles = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  getEmployeeTracking = async (parkId: number, type: 1 | 2) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getEmployeeTracking(token, parkId, type))
      if (type === 1) this.store.users.employeeTrackingReports = response.data
      if (type === 2) this.store.users.setAdditionalReports(response.data)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createEmployeeTrackingById = async (formData: FormData, rowIndex?: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.createEmployeeTracking(token, formData))
      response.type === '1' && this.store.users.editEmployeeTrackingReport(response, rowIndex)
      response.type === '2' && this.store.users.editAdditionalTrackingReport(response, rowIndex)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteEmployeeTrackingById = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteEmployeeTrackingById(token, id))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  editEmployeeTrackingById = async (id: number, string: string) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.editEmployeeTrackingById(token, id, string))
      response.type === 1 && this.store.users.editEmployeeTrackingReport(response)
      response.type === 2 && this.store.users.editAdditionalTrackingReport(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  getEmployeeReportById = async (id: number, from: string, to: string) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getEmployeeReportsById(token, id, from, to))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadSales = async (parkId: number, deleted = 0) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getSales(token, parkId, deleted))
      this.store.advertising.sales = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadAdminSales = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminSales(token))
      this.store.advertising.sales = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createSale = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.createSale(token, formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createAdminSale = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.createAdminSale(token, formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateSale = async (query: string, id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.updateSale(token, query, id))
      this.store.advertising.updateSales(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateAdminSale = async (query: string, id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.updateAdminSale(token, query, id))
      this.store.advertising.updateSales(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadSaleById = async (id: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getSaleById(token, id))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteSale = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteSale(token, id))
      this.store.advertising.deleteSale(id)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadNewsletter = async (parkId: number, deleted = 0) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getNewsletter(token, parkId, deleted))
      this.store.advertising.newsletter = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadNewsletterById = async (id: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getNewsletterById(token, id))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadNewsletterStateById = async (id: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getNewsletterStateById(token, id))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadAdminNewsletter = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminNewsletter(token))
      this.store.advertising.newsletter = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createNewsletter = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.createNewsletter(token, formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createAdminNewsletter = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.createAdminNewsletter(token, formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateNewsletter = async (query: string, id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.updateNewsletter(token, query, id))
      this.store.advertising.updateNewsletter(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateAdminNewsletter = async (query: string, id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.updateAdminNewsletter(token, query, id))
      this.store.advertising.updateNewsletter(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteNewsletter = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteNewsletter(token, id))
      this.store.advertising.deleteNewsLetter(id)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  sendPin = async (orderId: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.sendPin(orderId, token))

      return { status: true, pin: response.pin }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  agreemenentConfirm = async (orderId: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.agreementConfirm(orderId, token))

      return { status: true, pin: response.pin }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  checkPin = async (orderId: number, formData: FormData) => {
    const { token } = this.getAuthData()
    try {
      await task(this.api.checkPin(orderId, formData, token))

      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  generateLink = async (id: number, form: FormData) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.generateLink(id, form, token))

      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadDiscountById = async (id: number) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getDiscountById(token, id))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createAdminDiscount = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.createAdminDiscount(token, formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  createDiscount = async (formData: FormData) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.createDiscout(token, formData))
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateDiscount = async (query: string, id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.updateDiscount(token, query, id))
      this.store.advertising.updateDiscount(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  updateAdminDiscount = async (query: string, id: number) => {
    const { token } = this.getAuthData()

    try {
      const response = await task(this.api.updateAdminDiscount(token, query, id))
      this.store.advertising.updateDiscount(response)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }
  @actionAsync
  loadDiscounts = async (parkId: number, deleted = 0) => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getDiscounts(token, parkId, deleted))
      this.store.advertising.discounts = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadAdminDiscounts = async () => {
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.getAdminDiscounts(token))
      this.store.advertising.discounts = response
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  deleteDiscount = async (id: number) => {
    const { token } = this.getAuthData()

    try {
      await task(this.api.deleteDiscount(token, id))
      this.store.advertising.deleteDiscount(id)
      return { status: true }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }

  @actionAsync
  loadTotalReport = async (dateFrom?: string, dateTo?: string) => {
    const selectedPark = this.store.auth.selectedPark
    if (!selectedPark) {
      return
    }
    const { token } = this.getAuthData()
    try {
      const response = await task(this.api.loadTotal(token, selectedPark.id, dateFrom, dateTo))
      return { status: true, data: response }
    } catch (e: Partial<AxiosError<any>> | any) {
      return e.response ? { status: false, errors: e.response?.data?.errors } : { status: false }
    }
  }
}

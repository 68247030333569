import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { confirmDialog } from 'primereact/confirmdialog'
import { useContext } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { TGameForm } from '../../types'

export const PCZone = observer(() => {
  const methods = useFormContext<TGameForm>()

  const {
    auth: { selectedPark },
  } = useStore()

  const { fields, append, remove, update } = useFieldArray({
    name: 'pc',
    control: methods.control,
  })

  const logic = useLogic()
  const context = useContext(ToastContext)

  const pcValues = useWatch({
    name: 'pc',
    control: methods.control,
  })

  const deleteZone = async (index: number, id: number) => {
    const { status, errors } = await logic.deletePC(id)
    if (!status) {
      context?.toastShowHandler({ status, errors })
      return
    }

    context?.toastShowHandler({ status })
    const existingPcOptions = methods.getValues('pcOptions').filter((e) => e.api_id !== pcValues[index].api_id)
    methods.setValue('pcOptions', [...existingPcOptions])
    remove(index)
  }

  const removePCHandler = (index: number, id?: number) => {
    if (id)
      confirmDialog({
        message: 'Вы действительно хотите удалить ПК?',
        header: 'Удаление ПК',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptLabel: 'Да',
        rejectLabel: 'Нет',
        acceptClassName: 'p-button-danger',
        accept: async () => await deleteZone(index, id),
      })
    else {
      remove(index)
    }
  }

  const addPCHandler = () => {
    append({ name: '', key: '' })
  }

  const savePCHandler = async (index: number) => {
    const formData = new FormData()
    pcValues[index].name && formData.append('name', pcValues[index].name!)
    pcValues[index].monitor_id && formData.append('monitor_id', pcValues[index].monitor_id!)
    if (String(pcValues[index].key).split(':').length > 1) {
      formData.append('location', String(pcValues[index].key!).split(':')[0])
      formData.append('number', String(pcValues[index].key!).split(':')[1])
    }
    formData.append('park_id', String(selectedPark!.id))

    const { status, errors, id } = await logic.createPC(formData)
    if (status) {
      context?.toastShowHandler({ status })
      update(index, { ...pcValues[index], api_id: String(id) })
      const existingPcOptions = methods.getValues('pcOptions')
      existingPcOptions.push({ ...pcValues[index], api_id: String(id) })
      methods.setValue('pcOptions', [...existingPcOptions])
    } else context?.toastShowHandler({ status, errors })
  }

  const editPCHandler = async (index: number) => {
    const formData = new FormData()
    pcValues[index].name && formData.append('name', pcValues[index].name!)
    pcValues[index].monitor_id && formData.append('monitor_id', pcValues[index].monitor_id!)
    if (String(pcValues[index].key).split(':').length > 1) {
      formData.append('location', String(pcValues[index].key!).split(':')[0])
      formData.append('number', String(pcValues[index].key!).split(':')[1])
    }

    const { status, errors } = await logic.editPC(+pcValues[index].api_id!, formData)
    if (status) {
      context?.toastShowHandler({ status })
    } else context?.toastShowHandler({ status, errors })
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex gap-4 items-center'>
        <UiText size='lg' weight='bold'>
          Компы
        </UiText>
        <UiButton onClick={addPCHandler} type='button'>
          <i className='pi pi-plus text-sm'></i>
        </UiButton>
      </div>
      {fields.map((e, i) => (
        <div className='flex gap-4' key={e.id}>
          <FormInput
            className='w-[215px]'
            name={`pc.${i}.name`}
            placeholder='Введите название ПК'
            completed={e.saved}
          />
          <FormInput
            className='w-[215px]'
            name={`pc.${i}.key`}
            placeholder='Введите ID в формате (slug:1)'
            completed={e.saved}
          />
          <FormInput
            className='!w-[150px]'
            name={`pc.${i}.monitor_id`}
            placeholder='Введите monitor_id'
            type='number'
            completed={e.saved}
          />
          {e.api_id ? (
            <UiButton onClick={() => editPCHandler(i)} type='button'>
              <i className='pi pi-pencil text-sm'></i>
            </UiButton>
          ) : (
            <UiButton onClick={() => savePCHandler(i)} type='button' color='blue'>
              <i className='pi pi-plus text-sm'></i>
            </UiButton>
          )}
          <UiButton
            onClick={() => removePCHandler(i, e.api_id ? +e.api_id : undefined)}
            type='button'
            className='!bg-red'
          >
            <i className='pi pi-times text-sm'></i>
          </UiButton>
        </div>
      ))}
    </div>
  )
})
